import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getDomain } from '../utils/helpers'

const baseUrl = getDomain()
console.log('baseUrl: ', baseUrl)

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        headers.set(
            'Authorization',
            `Bearer ${window.localStorage.getItem('token')}`,
        )
        return headers
    },
})

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery,
    tagTypes: ['family', "families", 'saveZones', 'notifications', 'subscriptions', 'me'],
    endpoints: (builder) => ({}),
})

// export const {} = baseApi
