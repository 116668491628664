import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Header from '../../compoments/Header'

import { LanguagePage } from './Language'
import { NotificationPage } from './Notification'
import { ProfilePage } from './Profile'
import { SecurityPage } from './Security'
import { SettingsNav } from './SettingsNav'
import { SettingsPath } from './settingsTypes'

export const Settings: React.FC = () => {
    const { page } = useParams<{ page: SettingsPath }>()
    const navigate = useNavigate()
    console.log(page, 'page')
    useEffect(() => {
        if (!page) {
            navigate(SettingsPath.profile)
        }
    }, [page])

    return (
        <>
            <Header />
            <div className="wrapper header-fixed">
                <main className="content">
                    <div className="container">
                        <div className="settings">
                            <SettingsNav />
                            <div className="settings__body">
                                {page && page === SettingsPath.profile && (
                                    <ProfilePage />
                                )}
                                {page && page === SettingsPath.security && (
                                    <SecurityPage />
                                )}
                                {/* {page && page === SettingsPath.language && (
                                    <LanguagePage />
                                )} */}
                                {page &&
                                    page === SettingsPath.notifications && (
                                        <NotificationPage />
                                    )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
