/* eslint-disable jsx-a11y/label-has-associated-control */
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import FamilyMembers from '../assets/img/family-memders-icon.svg?react'
import MagnifyIcon from '../assets/img/ico-magnify.svg?react'
import NavigationIcon from '../assets/img/ico-navigation.svg?react'
import PremiumIcon from '../assets/img/premium-ico.svg?react'
import SearchIcon from '../assets/img/search-icon.svg?react'
import SexOffenders from '../assets/img/sesOffenders.svg?react'

import Button from './Button'
import clsx from 'clsx'

interface SearchFormProps {
    onSearch: (query: string, isNameSearch: boolean) => void
    isLoading: boolean
    isPremium: boolean
    isAuth: boolean
    updateModalOpen: () => void
}

const SearchForm: React.FC<SearchFormProps> = ({
    onSearch,
    isLoading,
    isPremium,
    isAuth,
    updateModalOpen,
}) => {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [isNameSearch, setIsNameSearch] = useState<boolean>(true)
    const [searchIsShow, setSearchIsShow] = useState<boolean>(false)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    // const [switchPage, setSwitchPage] = useState(false)
    const switchPage = pathname.includes("family")
    const params = useLocation()
    const showSwitcher = !params.pathname.includes('settings')

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value)
    }

    const handelPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!isPremium) {
            updateModalOpen()
            return
        }
        if (!isAuth) {
            navigate('/login-email')
            return
        }
        if (!switchPage) {
            navigate('/family')
            // setSwitchPage(pathname === '/family-members')
        } else {
            navigate('/?view=list_view')
            // setSwitchPage(pathname === '/family-members')
        }
    }

    const handleToggle = () => {
        setIsNameSearch((prev) => !prev)
        setSearchQuery('')
    }

    const handleSearch = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault()
        onSearch(searchQuery, false)
    }
    const { t } = useTranslation('home')

    return (
        <form className="search-form flex-auto">
            {/* {!searchIsShow ? ( */}
            <div className="form-actions active">
                {/* <label className="switch btn btn--lg switch-white-type switch-350">
                        <input
                            type="checkbox"
                            onChange={handelPageChange}
                            checked={switchPage}
                            className="hidden"
                        />
                        <span className="switch-active">
                            <span className="switch-active--bg"></span>
                        </span>
                        <div className="switch-controls">
                            <div className="switch-control">
                                <span className="ico">
                                    <SexOffenders />
                                </span>
                                <span className="switch-control--text">
                                    {t('sex_offenders')}
                                </span>
                            </div>
                            <div className="switch-control">
                                <span className="ico">
                                    {isPremium ? (
                                        <FamilyMembers />
                                    ) : (
                                        <PremiumIcon />
                                    )}
                                </span>
                                <span className="switch-control--text">
                                    {t('family')}
                                </span>
                            </div>
                        </div>
                    </label> */}
                <div className="tabs-nav-2 tabs-white-style">

                    <NavLink
                        to="/?view=map_view"
                        className={({ isActive }) => clsx(
                            'tab-link-3 btn btn--sm rounded-full',
                            {
                                'active-tab': isActive,
                            },
                        )}
                    >
                        <span className="ico">
                            <SexOffenders />
                        </span>
                        <span className="switch-control--text">
                            {t('sex_offenders')}
                        </span>
                    </NavLink>
                    <NavLink
                        to={!isAuth ? '/login-email' : "/family"}
                        onClick={(e) => {
                            if (!isPremium) {
                                e.preventDefault()
                                updateModalOpen()
                                return
                            }
                        }}
                        className={({ isActive }) => clsx(
                            'tab-link-3 btn btn--sm rounded-full',
                            {
                                'active-tab': isActive,
                            },
                        )}
                    >

                        <span className="ico">
                            {isPremium ? (
                                <FamilyMembers />
                            ) : (
                                <PremiumIcon />
                            )}
                        </span>
                        <span className="switch-control--text">
                            {t('family')}
                        </span>
                    </NavLink>

                </div>
                {/* <button
                        type="button"
                        onClick={() => setSearchIsShow(true)}
                        className="btn btn--lg btn--square btn--lg rounded-full show-search-form"
                    >
                        <svg
                            width="33"
                            height="32"
                            viewBox="0 0 33 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_960_40148)">
                                <path
                                    d="M14.5014 23.3333C19.564 23.3333 23.668 19.2293 23.668 14.1667C23.668 9.10405 19.564 5 14.5014 5C9.43877 5 5.33472 9.10405 5.33472 14.1667C5.33472 19.2293 9.43877 23.3333 14.5014 23.3333Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M20.9834 20.6487L27.3347 27"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_960_40148">
                                    <rect
                                        width="32"
                                        height="32"
                                        fill="white"
                                        transform="translate(0.334717)"
                                    ></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </button> */}
            </div>
            {/* ) : ( */}
            <div className="search-wrapper flex-auto active">
                {/* {showSwitcher && (
						<label className="switch btn btn--lg">
							<input
								type="checkbox"
								className="hidden"
								checked={!isNameSearch}
								onChange={handleToggle}
							/>
							<span className="switch-active">
								<span className="switch-active--bg"></span>
							</span>
							<div className="switch-controls">
								<div className="switch-control">
									<span className="switch-control--text">{t('by_name')}</span>
								</div>
								<div className="switch-control">
									<span className="switch-control--text">{t('by_city')}</span>
								</div>
							</div>
						</label>
					)} */}
                <div className="form-group input--lg input--icon-left input--icon-right search-input">
                    <div className="input-wrapper">
                        <span className="ico">
                            <MagnifyIcon />
                        </span>
                        <input
                            className="input input--solid rounded-full w-full"
                            type="text"
                            placeholder={t('search_placeholder')}
                            value={searchQuery}
                            onChange={handleInputChange}
                            onKeyDown={(e) => e.key === 'Enter' && handleSearch(e)}
                        />
                        <span className="ico">
                            <NavigationIcon />
                        </span>
                    </div>
                </div>
                <div className="flex gap--xs items-center">
                    <Button size='btn--md' isLoading={isLoading} onClick={handleSearch}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 33 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_960_40148)">
                                <path
                                    d="M14.5014 23.3333C19.564 23.3333 23.668 19.2293 23.668 14.1667C23.668 9.10405 19.564 5 14.5014 5C9.43877 5 5.33472 9.10405 5.33472 14.1667C5.33472 19.2293 9.43877 23.3333 14.5014 23.3333Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M20.9834 20.6487L27.3347 27"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_960_40148">
                                    <rect
                                        width="32"
                                        height="32"
                                        fill="white"
                                        transform="translate(0.334717)"
                                    ></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </Button>
                    {/* <button
                            type="button"
                            onClick={() => setSearchIsShow(false)}
                            className="btn btn--lg btn--square btn--lg rounded-full show-search-form"
                        >
                            <SearchIcon />
                        </button> */}
                </div>
            </div>
            {/* )} */}
        </form>
    )
}

export default SearchForm
