/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup'
import { getCountries, getPhoneCode } from 'libphonenumber-js'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'

import Input from '../../../compoments/Input'
import Modal from '../../../compoments/Modal'
import SelectBox from '../../../compoments/SelectBox'
import {
    useInviteFamilyMutation,
    useUpdateFamilyMemberMutation,
} from '../../../services/family'
import { familyMemberSchema } from '../../../shemas/validationSchema'
import type { IFamilyMember } from '../../../types'
import { ChangePhoto } from '../../Settings/Parts/ChangePhoto'
import { useParams } from "react-router-dom";

interface AddFamilyMemberProps {
    isOpen: boolean
    onClose: () => void
    editUser: IFamilyMember | null
    setEditUser: React.Dispatch<React.SetStateAction<IFamilyMember | null>>
}
const AddFamilyMember = ({
    isOpen,
    onClose,
    editUser,
    setEditUser,
}: AddFamilyMemberProps) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation('members')
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ...editUser,
        },
        resolver: yupResolver(familyMemberSchema),
    })
    const [sendInvite] = useInviteFamilyMutation()
    const [updateMember] = useUpdateFamilyMemberMutation()

    // const selectedCountry = watch('phoneCountry')
    const countries = getCountries()

    const onSubmit = async (dataForm: InferType<typeof familyMemberSchema>) => {
        const formData = new FormData()
        Object.entries(dataForm).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value)
            }
        })

        try {
            let res = null
            if (editUser) {
                res = await updateMember({
                    id: editUser.id,
                    body: formData,
                }).unwrap()
            } else {
                res = await sendInvite({ id, body: formData }).unwrap()
            }
            if (res.success) {
                closeHandler()
            } else {
                toast.error(res?.message || 'Something went wrong')
            }
        } catch (error) {
            console.error('rejected', error)
        }
    }
    const closeHandler = () => {
        setEditUser(null)
        onClose()
    }
    return (
        <Modal isOpen={isOpen} onClose={closeHandler}>
            <div className="modal__header">
                <div className="modal__header-block">
                    <h2 className="modal__header-title">
                        {editUser
                            ? 'Edit Family Member'
                            : t('add_member.title')}
                    </h2>
                    <p className="modal__header-description">
                        {t('add_member.description')}
                    </p>
                </div>
            </div>
            <div className="modal__body">
                <div className="row">
                    <div className="col-12 mb-44">
                        <ChangePhoto
                            src={watch('photo')}
                            onChange={(val) => setValue('photo', val)}
                        />
                    </div>
                    <div className="col-md-12 col-12 mb-22">
                        <Input
                            label={t('add_member.full_name')}
                            placeholder="Enter your full name..."
                            inputType="input--outline"
                            {...register('fullName')}
                            error={errors?.fullName?.message}
                        />
                    </div>
                    <div className="col-md-12 col-12 mb-22">
                        <div className="form-group input--lg">
                            <Input
                                label={t('add_member.email')}
                                placeholder="Enter your email..."
                                inputType="input--outline"
                                {...register('email')}
                                disabled={!!editUser}
                                error={errors?.email?.message}
                            />
                        </div>
                    </div>
                    {/* <div className="col-md-12 col-12 mb-28">
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group select--outline input--lg">
                                    <label className="label">Country</label>
                                    <SelectBox
                                        wrapperClassName={`select--outline input--sm standard-height`}
                                        options={countries.map((item) => ({
                                            label: `${item} +${getPhoneCode(item)}`,
                                            value: item,
                                        }))}
                                        selectValue={selectedCountry}
                                        onChange={(option) =>
                                            setValue(
                                                'phoneCountry',
                                                option?.value as string,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-group input--lg">
                                    <Input
                                        label={t('add_member.phone')}
                                        placeholder="Enter your phone number..."
                                        inputType="input--outline"
                                        {...register('phone')}
                                        error={errors?.phone?.message}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="modal__footer">
                <button
                    type="button"
                    onClick={closeHandler}
                    className="btn btn--secondary-light btn--lg rounded-full w-full"
                >
                    {t('add_member.cancel')}
                </button>
                <button
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn--primary btn--lg rounded-full w-full"
                >
                    {editUser ? `Save Changes` : t('add_member.send')}
                </button>
            </div>
        </Modal>
    )
}

export default AddFamilyMember
