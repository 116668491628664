import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { FAMILY_STATUS } from '../../constants/statuses'
import {
    useGetFamilyByIdQuery,
    useGetFamilyQuery,
    useGetSaveZonesQuery,
} from '../../services/family'
import type { IFamilyMember } from '../../types'

import FamilyOffenders from './components/FamilyOffenders'
import FamilySidebar from './components/FamilySidebar'

const FamilyMembersPage = () => {
    const { id } = useParams<{ id: string }>()
    const [selectedUser, setSelectedUser] = useState<IFamilyMember | null>(null)
    const { data: zonesData, isLoading: zonesIsLoading } = useGetSaveZonesQuery(
        { id },
    )
    const { data: familyData, isLoading: familyIsLoading } =
        useGetFamilyByIdQuery(id || '')
    const familyMembers = familyData?.data
    const saveZonesData = zonesData?.data || []
    if (familyIsLoading || zonesIsLoading) return null

    if (!familyMembers) {
        return null
    }

    return (
        <>
            <main className="content">
                <div className="container h-full">
                    <div className="catalog-page section-fullscreen headers-similars">
                        <FamilySidebar
                            saveZones={saveZonesData}
                            users={familyMembers?.invitations}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                        />
                        <FamilyOffenders
                            saveZones={saveZonesData}
                            users={familyMembers.invitations?.filter(
                                (user: IFamilyMember) =>
                                    user.status !== FAMILY_STATUS.Pending,
                            )}
                            family={familyMembers}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                        />
                    </div>
                </div>
            </main>
        </>
    )
}

export default FamilyMembersPage
