import { yupResolver } from '@hookform/resolvers/yup'
import {
    getCountries,
    getCountryCallingCode,
    getPhoneCode,
} from 'libphonenumber-js'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Input from '../../compoments/Input'
import SelectBox from '../../compoments/SelectBox'
import { useGetMeQuery, useUpdateMeMutation } from '../../services/auth'
import { changeProfileSchema } from '../../shemas/validationSchema'
import { returnOnlyNumbers } from '../../utils/helpers'

import { ChangePhoto } from './Parts/ChangePhoto'

enum UserDataEnum {
    fullName = 'fullName',
    email = 'email',
    phone = 'phone',
    photo = 'photo',
}

type UserData = {
    // [UserDataEnum.fullName]: string
    // [UserDataEnum.email]: string
    // [UserDataEnum.phone]: string
    [UserDataEnum.photo]: File | null | string
}

export const ProfilePage: React.FC = () => {
    const { t } = useTranslation('settings')
    const { data: dataUser, refetch } = useGetMeQuery()
    const [triggerUpdateMe] = useUpdateMeMutation()

    console.log(dataUser, 'dataUser')

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(changeProfileSchema),
    })

    // const selectedCountry = watch('phoneCountry')

    useEffect(() => {
        setValue('email', dataUser?.data?.email || '')
        setValue('fullName', dataUser?.data?.fullName || '')
        // setValue('phoneCountry', dataUser?.data?.phoneCountry || '')
        // setValue('phone', dataUser?.data?.phone || '')
        setValue('locationSharing', dataUser?.data?.locationSharing || false)
    }, [dataUser?.data])

    const [data, setData] = useState<UserData>({
        // [UserDataEnum.fullName]: '',
        // [UserDataEnum.email]: '',
        // [UserDataEnum.phone]: '',
        [UserDataEnum.photo]: null,
    })

    const [isReqLoading, setIsReqLoading] = useState(false)

    const [changedPhoto, setChangedPhoto] = useState(false)

    // const [errorsCustom, setErrorsErrorsCustom] = useState<{
    //   [key in keyof UserData]?: boolean
    // }>({})

    const changeData = (value: string | File, field: keyof UserData) => {
        setData((prev) => ({ ...prev, [field]: value }))
        // setErrorsErrorsCustom((prev) => ({ ...prev, [field]: false }))
    }

    const onSubmit = async (dataForm: {
        fullName: string
        email: string
        // country: string
        // phone: string
    }) => {
        const formData = new FormData()

        Object.entries(dataForm).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value)
            }
        })

        if (changedPhoto) {
            formData.append('photo', data[UserDataEnum.photo] as File)
        }

        try {
            setIsReqLoading(true)
            const res = await triggerUpdateMe(formData).unwrap()

            if (res.success) {
                refetch()

                toast.success('Profile updated successfully', {
                    autoClose: 3000,
                })
            } else {
                toast.error('Profile not updated ', {
                    autoClose: 3000,
                })
            }
            console.log(res, 'res update user')
        } catch (e) {
        } finally {
            setIsReqLoading(false)
        }
    }
    const countries = getCountries()
    return (
        <div className="tab active-tab" data-id="tab_profile-settings">
            <div className="settings__header">
                <h2 className="fw-500">{t('profile.title')}</h2>
                <button
                    disabled={isReqLoading}
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn--primary btn--sm radius-full"
                >
                    {t('save')}
                </button>
            </div>
            <div className="settings__body-content">
                <div
                    className="max-w-450"
                    data-select2-id="select2-data-8-2r59"
                >
                    <div className="row" data-select2-id="select2-data-7-r1wz">
                        <div className="col-12 mb-32">
                            <ChangePhoto
                                onChange={(val) => {
                                    changeData(val as File, UserDataEnum.photo)
                                    setChangedPhoto(true)
                                }}
                                src={dataUser?.data?.photo}
                            />
                        </div>
                        <div className="col-12 mb-22">
                            <div className="form-group input--lg">
                                <Input
                                    label={t('profile.name')}
                                    placeholder="Enter your full name..."
                                    {...register('fullName')}
                                    error={errors?.fullName?.message}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-22">
                        <div className="form-group input--lg">
                            <Input
                                label={t('profile.email')}
                                placeholder="Enter your email..."
                                {...register('email')}
                                error={errors?.email?.message}
                            />
                        </div>
                    </div>

                    <div className="row">
                        {/* <div
                            className="col-4"
                            data-select2-id="select2-data-6-mdo9"
                        >
                            <div
                                className="form-group select--outline input--lg"
                                data-select2-id="select2-data-5-upkz"
                            >
                                <label className="label">
                                    {t('profile.country')}
                                </label>
                                <SelectBox
                                    wrapperClassName={`select--outline input--sm standard-height ${errors.country?.message ? 'select-error' : ''}`}
                                    options={countries.map((item) => ({
                                        label: `${item} +${getPhoneCode(item)}`,
                                        value: item,
                                    }))}
                                    selectValue={selectedCountry}
                                    onChange={(option) =>
                                        setValue(
                                            'phoneCountry',
                                            option?.value as string,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-8 mb-22">
                            <div className="form-group input--lg">
                                <Input
                                    label={t('profile.phone')}
                                    {...register('phone')}
                                    error={errors?.phone?.message}
                                />
                            </div>
                        </div> */}
                        <div className="col-8">
                            <div className="form-group input--lg">
                                <label className="switch-control mb-20">
                                    <input
                                        type="checkbox"
                                        hidden
                                        {...register('locationSharing')}
                                    />
                                    <div className="switch-btn">
                                        <div className="switch-point-wrapp">
                                            <span className="switch-point"></span>
                                        </div>
                                    </div>
                                    <p className="text--md">
                                        {t('profile.locationSharing')}
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
