import type { ChangeEvent } from 'react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg?react'
import EarthIcon from '../../../assets/img/ico-earth.svg?react'
import FilterIcon from '../../../assets/img/ico-filter.svg?react'
import MagnifyIcon from '../../../assets/img/ico-magnify.svg?react'
import ZipCodeIcon from '../../../assets/img/ico-zipcode.svg?react'
import Checkbox from '../../../compoments/Checkbox'
import DownloadAppButtonGroup from '../../../compoments/DownloadAppButtonGroup'
import InputRange from '../../../compoments/InputRange'
import {
    getCurrentSubscription,
    getIsExpired,
    getIsPremium,
} from '../../../features/auth/authSlice'
import { useAppSelector } from '../../../hooks/redux'
import { stateDataObj } from '../../../staticData/states'
import { SearchFilters } from '../index'

import Accordion from './Accordion'

type FilterCategory = 'states' | 'zipcodes' | 'range'

interface OptionObj {
    label: string
    value: string
}

interface FilterOptions {
    title: string
    icon: React.ReactElement
    options?: OptionObj[] | []
    min?: number
    max?: number
    defaultValues?: number[]
    placeholder?: string
}

const SidebarFilters = () => {
    const isFirstRender = useRef(true)
    const { t } = useTranslation('home')
    const filters: Record<FilterCategory, FilterOptions> = {
        states: {
            title: 'filters.states',
            icon: <EarthIcon />,
            options: [],
            defaultValues: [],
            placeholder: 'filters.states_placeholder',
        },
        zipcodes: {
            title: 'filters.zip',
            icon: <ZipCodeIcon />,
            options: [],
            defaultValues: [],
            placeholder: 'filters.zip_placeholder',
        },
        range: {
            title: 'filters.age',
            icon: <AgeRangeIcon />,
            min: 0,
            max: 100,
            defaultValues: [0, 100],
        },
    }
    const [search, setSearch] = useState({
        states: '',
        zipcodes: '',
    })

    const [searchParams, setSearchParams] = useSearchParams()

    const statesFromParams = searchParams.get(SearchFilters.STATES) || ''
    const zipsFromParams = searchParams.get(SearchFilters.ZIPCODES) || ''
    const rangeFromParams = searchParams.get(SearchFilters.RANGE) || ''
    const isPremium = useSelector(getIsPremium)
    const isExpired = useSelector(getIsExpired)
    const subscription = useAppSelector(getCurrentSubscription)
    const states = statesFromParams ? statesFromParams.split(',') : []
    const zipcodes = zipsFromParams ? zipsFromParams.split(',') : []
    const range = rangeFromParams
        ? rangeFromParams.split(',').map((el) => +el)
        : []

    const [currentFilters, setCurrentFilters] = useState(filters)

    const { offendersAllData } = useAppSelector((state) => state.offenders)

    const { zipcodeFacet, stateFacet } = useMemo(() => {
        return offendersAllData || { zipcodeFacet: [], stateFacet: [] }
    }, [offendersAllData])

    useEffect(() => {
        let states: FilterOptions['options'] = []
        let zips: FilterOptions['options'] = []

        if (stateFacet && Object.keys(stateFacet).length) {
            states = Object.keys(stateFacet).map((key) => ({
                label: stateDataObj[key] || key,
                value: key,
            }))
        }

        if (zipcodeFacet && Object.keys(zipcodeFacet).length) {
            zips = Object.keys(zipcodeFacet).map((key) => ({
                label: key,
                value: key,
            }))
        }

        setCurrentFilters((prevState) => ({
            ...prevState,
            states: {
                ...prevState.states,
                options: states,
            },
            zipcodes: {
                ...prevState.zipcodes,
                options: zips,
            },
        }))
    }, [stateFacet, zipcodeFacet])

    const handleSearch = (name: keyof FilterCategory, value: string) => {
        setSearch((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleCheckboxChange = (
        category: FilterCategory,
        value: string,
        checked: boolean,
    ) => {
        setSearchParams((prev) => {
            const values = prev.get(category)?.split(',') || []
            if (!values.includes(value)) {
                values.push(value)
                prev.set(category, values.toString())
            } else {
                prev.set(
                    category,
                    values.filter((item) => item !== value).toString(),
                )
            }
            return prev
        })
    }

    const handleSelectAll = (category: FilterCategory, checked: boolean) => {
        const options =
            currentFilters[category].options?.map((el) => el.value) || []
        setSearchParams((prev) => {
            if (checked) {
                prev.delete(category)
            } else {
                currentFilters[category].options?.map((el) => {
                    prev.append(category, el.value)
                })
            }
            if (checked) {
                prev.set(category, options.toString())
            } else {
                prev.set(category, [].toString())
            }
            return prev
        })
    }

    const handleReset = (category: FilterCategory) => {
        setSearchParams((prev) => {
            prev.delete(category)
            return prev
        })
    }

    const handleSidebarToggle = () => {
        document.querySelector('body')?.classList.toggle('--toggle-side')
    }

    const handleSidebarClose = () => {
        document.querySelector('body')?.classList.remove('--toggle-side')
    }
    useEffect(() => {
        const overlay = document.querySelector(
            '.custom-overlay.side-toggler-js',
        )
        overlay?.addEventListener('click', handleSidebarClose)
        return () => overlay?.removeEventListener('click', handleSidebarClose)
    }, [])

    const requestsCount =
        typeof subscription?.searchCount === 'number' &&
        typeof subscription?.searchMax === 'number'
            ? subscription.searchMax - subscription.searchCount
            : 0

    return (
        <div className="catalog-page-aside--wrapper">
            <button
                onClick={handleSidebarToggle}
                className="btn btn--square btn--xl btn-aside side-toggler-js"
            >
                <span className="ico">
                    <FilterIcon />
                </span>
            </button>
            <div className="catalog-page-aside scroll-styler">
                {/* {isExpired && subscription && (
                    <div className="free-searches-block">
                        <span className="gradient-text">
                            {requestsCount} / {subscription.searchMax}
                        </span>{' '}
                        {t('searches_used')}
                    </div>
                )} */}
                <div className="aside-header header-similar">
                    <h2 className="heading font-500">{t('filters.title')}</h2>
                </div>
                <div className="aside-body">
                    <ul className="filters-list">
                        {Object.entries(currentFilters).map(
                            ([
                                key,
                                { title, icon, options, min, max, placeholder },
                            ]) => (
                                <li key={key} className="filters-list--item">
                                    <Accordion
                                        title={t(title)}
                                        onReset={
                                            searchParams.get(
                                                key as FilterCategory,
                                            )?.length
                                                ? () =>
                                                      handleReset(
                                                          key as FilterCategory,
                                                      )
                                                : undefined
                                        }
                                        icon={icon}
                                    >
                                        {options ? (
                                            <div className="filter-results">
                                                <div className="form-group input--sm input--icon-left">
                                                    <div className="input-wrapper">
                                                        <span className="ico">
                                                            <MagnifyIcon />
                                                        </span>
                                                        <input
                                                            onChange={(e) =>
                                                                handleSearch(
                                                                    key as keyof Exclude<
                                                                        FilterCategory,
                                                                        'range'
                                                                    >,
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            value={
                                                                search[
                                                                    key as Exclude<
                                                                        FilterCategory,
                                                                        'range'
                                                                    >
                                                                ]
                                                            }
                                                            className="input input--solid rounded-full w-full"
                                                            type="text"
                                                            placeholder={
                                                                placeholder
                                                                    ? t(
                                                                          placeholder,
                                                                      )
                                                                    : `Search ${title.toLowerCase()}...`
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <ul className="filter-results--list scroll-styler">
                                                    {options.length ? (
                                                        <li className="filter-results--list--item">
                                                            <Checkbox
                                                                text={t(
                                                                    'filters.select_all',
                                                                )}
                                                                checked={options.every(
                                                                    (option) =>
                                                                        (
                                                                            searchParams
                                                                                .get(
                                                                                    key as FilterCategory,
                                                                                )
                                                                                ?.split(
                                                                                    ',',
                                                                                ) ||
                                                                            []
                                                                        )?.includes(
                                                                            option.value as never,
                                                                        ),
                                                                )}
                                                                onChange={(
                                                                    e: ChangeEvent<HTMLInputElement>,
                                                                ) =>
                                                                    handleSelectAll(
                                                                        key as FilterCategory,
                                                                        e.target
                                                                            .checked,
                                                                    )
                                                                }
                                                            />
                                                        </li>
                                                    ) : null}
                                                    {options
                                                        .filter((option) =>
                                                            option.label
                                                                .toLowerCase()
                                                                .includes(
                                                                    search[
                                                                        key as Exclude<
                                                                            FilterCategory,
                                                                            'range'
                                                                        >
                                                                    ].toLowerCase(),
                                                                ),
                                                        )
                                                        .map(
                                                            (option, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="filter-results--list--item"
                                                                >
                                                                    <Checkbox
                                                                        text={
                                                                            option.label
                                                                        }
                                                                        checked={(
                                                                            searchParams
                                                                                .get(
                                                                                    key as FilterCategory,
                                                                                )
                                                                                ?.split(
                                                                                    ',',
                                                                                ) ||
                                                                            []
                                                                        )?.includes(
                                                                            option.value as never,
                                                                        )}
                                                                        onChange={(
                                                                            e: ChangeEvent<HTMLInputElement>,
                                                                        ) =>
                                                                            handleCheckboxChange(
                                                                                key as FilterCategory,
                                                                                option.value,
                                                                                e
                                                                                    .target
                                                                                    .checked,
                                                                            )
                                                                        }
                                                                    />
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="widget-range">
                                                <InputRange
                                                    min={min || 0}
                                                    max={max || 100}
                                                    values={
                                                        range?.length
                                                            ? range
                                                            : filters.range
                                                                  .defaultValues
                                                    }
                                                    tipFormatter={(value) =>
                                                        `${value} ${t('filters.years')}`
                                                    }
                                                    onChangeComplete={(
                                                        values,
                                                    ) =>
                                                        setSearchParams(
                                                            (prev) => {
                                                                prev.set(
                                                                    SearchFilters.RANGE,
                                                                    values.toString(),
                                                                )
                                                                return prev
                                                            },
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Accordion>
                                </li>
                            ),
                        )}
                    </ul>
                    <DownloadAppButtonGroup />
                </div>
            </div>
        </div>
    )
}

export default SidebarFilters
