import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '../../assets/img/ico-calc-plus.svg?react'
import Button from '../../compoments/Button'
import Pagination from '../../compoments/Pagination'
import {
    getCurrentSubscription,
    getIsExpired,
    getIsPremium,
} from '../../features/auth/authSlice'
import { useAppSelector } from '../../hooks/redux'
import { useTabs } from '../../hooks/useTabs'
import { useGetMeQuery } from '../../services/auth'
import { useGetProfilesUsersMutation } from '../../services/users'

import AddAlertModal from './components/AddAlertModal'
import NameAlertsTable from './components/NameAlertsTable'
import ZipCodeAlertsTable from './components/ZipCodeAlertsTable'
import React from 'react'
import { useModal } from '../../hooks/useModal'

const AlertsPage = () => {
    const { t } = useTranslation('alerts')
    const tabs = [
        { id: 'name_alerts', label: t('name_tab') },
        { id: 'zip_codes_alerts', label: t('zip_tab') },
    ] as const

    const { activeTab, setActiveTab } = useTabs(tabs)
    const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false)
    const [activeAlert, setActiveAlert] = useState(null)
    const subscription = useAppSelector(getCurrentSubscription)
    const isPremium = useAppSelector(getIsPremium)
    const isExpired = useAppSelector(getIsExpired)
    const [triggerGetUsersProfiles] = useGetProfilesUsersMutation()
    const { data: userData, refetch } = useGetMeQuery()

    // const nameAlerts = Array.from({ length: 12 }).map((_, index) => ({
    //   id: index,
    //   name: 'Bessie Cooper',
    //   address: '1901 Thornridge Cir. Shiloh, Hawaii',
    // }))
    const { showModal } = useModal()
    const [nameAlerts, setNameAlerts] = useState<[] | any>([])

    const [zipCodes, setZipCodes] = useState<
        [] | { zip: string; range: number }[]
    >([])

    const req = async (arr: string[]) => {
        const res = await triggerGetUsersProfiles({
            offenderDocIds: arr,
        }).unwrap()

        if (res) {
            setNameAlerts(res.offenders)
        }
    }
    function getItemsForPage(
        items: any[],
        currentPage: number,
        itemsPerPage = 10,
    ): any[] {
        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage
        return items.slice(startIndex, endIndex)
    }
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if (userData?.data?.zipcodes) {
            const arr = Object.entries(userData.data.zipcodes).map(
                ([key, value]) => ({
                    zip: key as string,
                    range: value as number,
                }),
            )
            setZipCodes(arr)
        }

        if (userData?.data?.offenders) {
            req(getItemsForPage(userData?.data?.offenders, currentPage))
        }
    }, [userData?.data, currentPage])

    const editAlertZip = (alert: any) => {
        setIsAddAlertModalOpen(true)
        setActiveAlert(alert)
    }


    const handleShowUpgradeModal = () => {
        showModal('upgrade')
    }

    const alerstLeft = subscription?.alertsCount

    const canAddAlerts =
        (subscription?.alertsCount && subscription?.alertsCount > 0) ||
        !isExpired
    return (
        <main className="content">
            <div className="container h-full">
                <div className="catalog-page section-fullscreen headers-similars">
                    <div className="catalog-page-content w-full">
                        <div className="card card-border h-full">
                            <div className="card-header border-none">
                                <div className="card-header--item">
                                    <h1 className="heading font-500">
                                        {t('title')}
                                    </h1>
                                    {/* {isExpired && (
                                        <div
                                            className="btn btn--md  btn--gradient rounded-full"
                                            style={{
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {alerstLeft} alerts left
                                        </div>
                                    )} */}
                                </div>

                                <div className="card-header--item">
                                    {!isExpired ? (
                                        <Button
                                            onClick={() =>
                                                setIsAddAlertModalOpen(true)
                                            }
                                        >
                                            <span className="ico">
                                                <PlusIcon />
                                            </span>
                                            {t('add_alert')}
                                        </Button>
                                    ) :

                                        <button
                                            onClick={handleShowUpgradeModal}
                                            type="button"
                                            className="btn btn--lg  btn--gradient rounded-full"
                                        >
                                            <span className="ico">
                                                <svg
                                                    width="22"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M19.8083 12.4319L20.0191 10.1989C20.1841 8.44621 20.2666 7.56896 19.9659 7.20687C19.8901 7.11277 19.7963 7.03461 19.6901 6.97687C19.584 6.91913 19.4674 6.88297 19.3472 6.87046C18.9108 6.83196 18.3636 7.45529 17.2682 8.70196C16.7017 9.34729 16.4184 9.66904 16.1031 9.71946C15.927 9.7471 15.7466 9.71822 15.5879 9.63696C15.2955 9.49029 15.1021 9.09154 14.7125 8.29496L12.6619 4.09296C11.9268 2.58687 11.5592 1.83337 11 1.83337C10.4408 1.83337 10.0733 2.58687 9.33809 4.09296L7.2875 8.29496C6.89884 9.09246 6.7045 9.49029 6.41209 9.63696C6.25321 9.71762 6.07304 9.74647 5.89692 9.71946C5.58159 9.66904 5.29834 9.34729 4.73184 8.70196C3.63642 7.45529 3.08917 6.83196 2.65284 6.87046C2.53261 6.88297 2.41605 6.91913 2.30986 6.97687C2.20367 7.03461 2.10995 7.11277 2.03409 7.20687C1.73434 7.56896 1.81684 8.44621 1.98184 10.1989L2.19175 12.4319C2.53825 16.1095 2.71059 17.9493 3.79592 19.0575C4.8785 20.1667 6.50284 20.1667 9.75334 20.1667H12.2458C15.4953 20.1667 17.1197 20.1667 18.2041 19.0575C19.2885 17.9484 19.4618 16.1095 19.8083 12.4319Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <span>Upgrade Premium</span>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="card-body overflow-hidden">
                                <div className="table-block h-full">
                                    <div className="table-header tabs-nav">
                                        {tabs.map((tab) => (
                                            <button
                                                key={tab.id}
                                                className={clsx(
                                                    'tab-link-2 btn btn--md',
                                                    {
                                                        'active-tab':
                                                            activeTab ===
                                                            tab.id,
                                                    },
                                                )}
                                                onClick={() =>
                                                    setActiveTab(tab.id)
                                                }
                                            >
                                                <div className="tab-link-2--content text--lg">
                                                    <span>{tab.label}</span>
                                                    <span className="count-pill">
                                                        {tab.id ===
                                                            'zip_codes_alerts'
                                                            ? zipCodes.length
                                                            : userData?.data
                                                                ?.offenders
                                                                ?.length}
                                                    </span>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                    <div className="table-body overflow-hidden h-full">
                                        {activeTab === 'name_alerts' && (
                                            <div className="tab h-full active-tab">
                                                <NameAlertsTable
                                                    alerts={nameAlerts}
                                                    onDelete={() => { }}
                                                />
                                            </div>
                                        )}
                                        {activeTab === 'zip_codes_alerts' && (
                                            <div className="tab h-full active-tab">
                                                <ZipCodeAlertsTable
                                                    alerts={zipCodes}
                                                    onDelete={() => { }}
                                                    onEdit={(alert) =>
                                                        editAlertZip(alert)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {userData?.data?.offenders.length && (
                                <div className="card-footer">
                                    <Pagination
                                        pageCount={
                                            activeTab === 'name_alerts'
                                                ? Math.ceil(
                                                    userData?.data?.offenders
                                                        ?.length / 10,
                                                ) || 1
                                                : 1
                                        }
                                        currentPage={
                                            activeTab === 'name_alerts'
                                                ? currentPage
                                                : 1
                                        }
                                        onPageChange={(page) => {
                                            setCurrentPage(page)
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <AddAlertModal
                onSubmit={() => { }}
                isOpen={isAddAlertModalOpen}
                activeAlert={activeAlert}
                onClose={() => {
                    setActiveAlert(null)
                    setIsAddAlertModalOpen(false)
                }}
            />
        </main>
    )
}

export default AlertsPage
