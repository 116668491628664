import { Link } from 'react-router-dom'

import AppleIcon from '../assets/img/icon-apple.svg?react'
import CallIcon from '../assets/img/icon-call.svg?react'
import EmailIcon from '../assets/img/icon-email.svg?react'
import GoogleIcon from '../assets/img/icon-google.svg?react'
import { useLazyLoginGoogleQuery } from '../services/auth'
import { getMediaUrl } from '../utils/helpers'

interface SocialButtonsProps {
    button: 'call' | 'email'
}

const SocialButtons: React.FC<SocialButtonsProps> = ({ button }) => {
    const [triggerLoginGoogle] = useLazyLoginGoogleQuery()

    return (
        <div className="servis-link-group ">
            <a
                href={getMediaUrl('/oauth/google', '')}
                // onClick={(e) => {
                //   e.preventDefault()
                //
                //   triggerLoginGoogle()
                // }}
                className="btn btn--xl servis-link rounded-full flex-1"
            >
               <span className='ico'> <GoogleIcon /></span> 
               <span>Google</span>
            </a>
            <a
                href={getMediaUrl('/oauth/apple', '')}
                className="btn btn--xl servis-link rounded-full flex-1"
            >
               <span className='ico'><AppleIcon /></span>  
               <span>Apple</span>
            </a>
            {/* <button
                onClick={(e) => e.preventDefault()}
                className="servis-link ico ico--xl rounded-full"
            >
                {button === 'call' ? (
                    <Link to="/login-phone">
                        <CallIcon />
                    </Link>
                ) : (
                    <Link to="/login-email">
                        <EmailIcon />
                    </Link>
                )}
            </button> */}
        </div>
    )
}

export default SocialButtons
