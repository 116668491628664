/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import ListViewIcon from '../../../assets/img/ico-list.svg?react'
import MapViewIcon from '../../../assets/img/ico-map-view.svg?react'
import WarningIcon from '../../../assets/img/warning-icon.svg?react'
import Pagination from '../../../compoments/Pagination'
import SelectBox from '../../../compoments/SelectBox'
import { useAppSelector } from '../../../hooks/redux'
import { useTabs } from '../../../hooks/useTabs'
import { useGetMeQuery } from '../../../services/auth'
import type { IOffender } from '../../../types'
import { SearchFilters } from '../index'

import CardPerson from './CardPerson'
import OffenderPopup from './OffenderPopup'
import { Crimes } from '../../../services/types'
import { toast } from 'react-toastify'
import CrimesPopup from './CrimesPopup'
import MapOffender from './Map'
import { OffendersMap } from "../../FamilyPage/components/Map";
import { getIsExpired, getIsPremium } from '../../../features/auth/authSlice'
import { useModal } from '../../../hooks/useModal'
import { maxShowItemsForExpiretUser } from '../../../constants/statuses'

const tabs = [
    {
        id: 'list_view',
        label: 'List View',
        icon: <ListViewIcon />,
    },
    {
        id: 'map_view',
        label: 'Map View',
        icon: <MapViewIcon />,
    },
] as const

const filterButtons = [
    {
        id: 'total',
        label: 'Total',
    },
    {
        id: 'sex_offenders',
        label: 'Sex Offenders',
    },
    {
        id: 'crimes',
        label: 'Crimes',
    },
] as const

const showUserPerPage = window.innerWidth < 992 ? 10 : 15

export const Offenders = () => {
    const { t } = useTranslation('home')
    const [searchParams] = useSearchParams()
    const [offenderPopup, setOffenderPopup] = useState<IOffender | null>(null)
    const [crimesPopup, setCrimesPopup] = useState<Crimes | null>(null)
    const isPremium = useAppSelector(getIsPremium)
    const isExpired = useAppSelector(getIsExpired)
    const { showModal } = useModal()
    const sortOptions = [
        {
            label: t('sort.default'),
            value: 'default',
        },
        {
            label: t('sort.by_range'),
            value: 'range',
        },
        {
            label: t('sort.by_name'),
            value: 'name',
        },
        {
            label: t('sort.by_state'),
            value: 'state',
        },
    ]
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedSortOption, setSelectedSortOption] = useState<string | null>(
        null,
    )
    const [locationRequested, setLocationRequested] = useState(false)
    const [myLocation, setMyLocation] = useState<[number, number] | null>(null)
    const { activeTab, setActiveTab } = useTabs(tabs)
    const [currentFilter, setCurrentFilter] = useState<string>(filterButtons[0].id)
    const { isLoading, offendersAllData, crimesData, searchByZip } = useAppSelector(
        (state) => state.offenders,
    )

    const { data: userData } = useGetMeQuery()

    const selectedOffenders = userData?.data?.offenders || []

    const offenders: IOffender[] | null = offendersAllData?.offenders || null
    const crimes: Crimes[] | null = crimesData?.data || null
    // const [zipCodes, setZipCodes] = useState<string[]>([])
    // const [states, setStates] = useState<string[]>([])
    // const [range, setRange] = useState<string[]>([])
    const zipCodes = searchParams.get(SearchFilters.ZIPCODES)
    const states = searchParams.get(SearchFilters.STATES)
    const range = searchParams.get(SearchFilters.RANGE)
    // useEffect(() => {
    //   setZipCodes(
    //     searchParams
    //       .get(SearchFilters.ZIPCODES)
    //       ?.split(',')
    //       .filter((el) => el) || [],
    //   )
    //   setStates(
    //     searchParams
    //       .get(SearchFilters.STATES)
    //       ?.split(',')
    //       .filter((el) => el) || [],
    //   )
    //   setRange(
    //     searchParams
    //       .get(SearchFilters.RANGE)
    //       ?.split(',')
    //       .filter((el) => el) || [],
    //   )
    // }, [searchParams])
    useEffect(() => {
        if (currentFilter === 'total') { setActiveTab('map_view') }
    }, [currentFilter])
    const filteredOfferends = useMemo(() => {
        if (!offenders || !offenders.length) return []

        let newOfferends = [...offenders]

        if (zipCodes && zipCodes.length > 0) {
            newOfferends = newOfferends.filter(
                (el) =>
                    el.address?.length &&
                    el.address.some((address) =>
                        zipCodes.includes(address.zipcode),
                    ),
            )
        }

        if (states && states.length > 0) {
            newOfferends = newOfferends.filter(
                (el) =>
                    el.address?.length &&
                    el.address.some((address) =>
                        states.includes(address.state),
                    ),
            )
        }

        if (range && range.length > 0) {
            newOfferends = newOfferends.filter(
                (el) => el.age >= +range[0] && el.age <= +range[1],
            )
        }

        setCurrentPage(1)

        return newOfferends
    }, [zipCodes, states, offenders])

    const pageCount = Math.ceil(filteredOfferends.length / showUserPerPage)
    const handleShowUpgradeModal = () => {
        showModal('upgrade')
    }
    let newFilteredOfferends = [...filteredOfferends]

    if (selectedSortOption) {
        switch (selectedSortOption) {
            case 'range':
                newFilteredOfferends.sort((a, b) => a.age - b.age)
                break
            case 'name':
                newFilteredOfferends.sort((a, b) =>
                    a.offenderName.localeCompare(b.offenderName),
                )
                break
            case 'state':
                newFilteredOfferends.sort((a, b) =>
                    a.address && b.address?.length
                        ? a.address[0].state.localeCompare(b.address[0].state)
                        : -1,
                )
                break
            case 'default':
                newFilteredOfferends = [...filteredOfferends]
                break
        }
    }
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setMyLocation([
                        position.coords.longitude,
                        position.coords.latitude,
                    ])
                    setLocationRequested(true)
                },
                (error) => {
                    toast.warn(
                        `Geolocation access denied. Please enable geolocation in your browser settings`,
                    )
                    setLocationRequested(true)
                    console.log(error.message)
                },
            )
        } else {
            setLocationRequested(true)
            toast.warn('Geolocation is not supported by your browser.')
            console.log('Geolocation is not supported by your browser.')
        }
    }, [])
    const chekIndexForPremiumMore = (index: number) => {
        return isExpired && (currentPage > 1 || (index + 1 > maxShowItemsForExpiretUser))
    }
    const chekIndexForPremium = (index: number) => {
        return isExpired && (index + 1 === maxShowItemsForExpiretUser)
    }
    const actualOffenders = newFilteredOfferends.slice(
        currentPage === 1 ? 0 : (currentPage - 1) * showUserPerPage,
        currentPage * showUserPerPage,
    )

    return (
        <div className="catalog-page-content">
            <div className="card card-border h-full scroll-card">
                <div className="card-header header-similar card-header-catalog">
                    <div className="card-header--item card-header--item--overflow">
                        <h2 className="heading font-500 card-header--item--title">
                            {/* {t('title')}{' '} */}
                            Near you
                            {/* {!searchByZip && <span className="dark-grey-light">
                                ({filteredOfferends.length})
                            </span>} */}
                        </h2>
                        <div className="flex items-center flex-wrap">
                            {filterButtons.map((tab) => (
                                <button
                                    key={tab.id}
                                    className={clsx(
                                        'tab-link-3 btn btn--sm rounded-full',
                                        {
                                            'active-tab': currentFilter === tab.id,
                                        },
                                    )}
                                    onClick={() => {
                                        setCurrentFilter(tab.id)
                                    }}
                                >
                                    {/* <span className="ico">{tab.icon}</span> */}
                                    <span>
                                        {tab.label}
                                    </span>
                                    <span>{tab.id === "crimes" ? crimesData?.total || 0 : tab.id === "sex_offenders" ? filteredOfferends.length : (crimesData?.total || 0) + filteredOfferends.length}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="card-header--item">
                        {currentFilter !== 'total' && <div className="tabs-nav-2 tabs-white-style">
                            {tabs.map((tab) => (
                                <button
                                    key={tab.id}
                                    className={clsx(
                                        'tab-link-3 btn btn--sm rounded-full',
                                        {
                                            'active-tab': activeTab === tab.id,
                                        },
                                    )}
                                    onClick={() => setActiveTab(tab.id)}
                                >
                                    <span className="ico">{tab.icon}</span>
                                    {/* <span className="tab-link-3--catalog-title">
                                        {t(tab.id)}
                                    </span> */}
                                </button>
                            ))}
                        </div>}
                        <SelectBox
                            wrapperClassName={
                                'select--outline input--sm select-w-catalog'
                            }
                            options={sortOptions}
                            selectValue={selectedSortOption}
                            onChange={(el) =>
                                setSelectedSortOption(el?.value as string)
                            }
                            placeholder={t('sort.select')}
                        />
                    </div>
                </div>
                <div className="card-body p-0 scroll-card h-full">
                    {
                        !isLoading &&
                            actualOffenders &&
                            actualOffenders.length ? (
                            <>
                                {activeTab === 'list_view' && (
                                    <>
                                        {currentFilter === 'sex_offenders' && <div
                                            className={`tab h-full active-tab`}
                                            data-id="list-view"
                                        >
                                            <div className="card h-full scroll-card">
                                                <div
                                                    className="card-header"
                                                    style={{ display: 'none' }}
                                                ></div>
                                                <div className="card-body">
                                                    <div
                                                        // className="custom-row custom-row-5 gutters-1"
                                                        className='row gutters-1'
                                                    >
                                                        {actualOffenders.length > 0 ? actualOffenders.map(
                                                            (el, index) => (
                                                                <React.Fragment key={el.id}>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (chekIndexForPremiumMore(index)) {
                                                                                handleShowUpgradeModal()
                                                                            } else {
                                                                                setOffenderPopup(el)
                                                                            }

                                                                        }}
                                                                        // className="custom-col"
                                                                        className={clsx('col-lg-4 col-md-6 relative ', {
                                                                           'blur-item': chekIndexForPremiumMore(index)
                                                                        })}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <CardPerson
                                                                            data={el}
                                                                            selectedOffenders={
                                                                                selectedOffenders
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {chekIndexForPremium(index) && (
                                                                        <div className='col-lg-4 col-md-6 relative'>
                                                                            <div className="upgrade-card">
                                                                                <p>You’ve reached your free record limit. Unlock full access to offender data and advanced safety tools by subscribing!</p>
                                                                                <button
                                                                                    onClick={handleShowUpgradeModal}
                                                                                    type="button"
                                                                                    className="btn btn--lg  btn--gradient rounded-full"
                                                                                >
                                                                                    <span className="ico">
                                                                                        <svg
                                                                                            width="22"
                                                                                            height="22"
                                                                                            viewBox="0 0 22 22"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M19.8083 12.4319L20.0191 10.1989C20.1841 8.44621 20.2666 7.56896 19.9659 7.20687C19.8901 7.11277 19.7963 7.03461 19.6901 6.97687C19.584 6.91913 19.4674 6.88297 19.3472 6.87046C18.9108 6.83196 18.3636 7.45529 17.2682 8.70196C16.7017 9.34729 16.4184 9.66904 16.1031 9.71946C15.927 9.7471 15.7466 9.71822 15.5879 9.63696C15.2955 9.49029 15.1021 9.09154 14.7125 8.29496L12.6619 4.09296C11.9268 2.58687 11.5592 1.83337 11 1.83337C10.4408 1.83337 10.0733 2.58687 9.33809 4.09296L7.2875 8.29496C6.89884 9.09246 6.7045 9.49029 6.41209 9.63696C6.25321 9.71762 6.07304 9.74647 5.89692 9.71946C5.58159 9.66904 5.29834 9.34729 4.73184 8.70196C3.63642 7.45529 3.08917 6.83196 2.65284 6.87046C2.53261 6.88297 2.41605 6.91913 2.30986 6.97687C2.20367 7.03461 2.10995 7.11277 2.03409 7.20687C1.73434 7.56896 1.81684 8.44621 1.98184 10.1989L2.19175 12.4319C2.53825 16.1095 2.71059 17.9493 3.79592 19.0575C4.8785 20.1667 6.50284 20.1667 9.75334 20.1667H12.2458C15.4953 20.1667 17.1197 20.1667 18.2041 19.0575C19.2885 17.9484 19.4618 16.1095 19.8083 12.4319Z"
                                                                                                fill="currentColor"
                                                                                            ></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span>Upgrade to Premium</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>)}
                                                                </React.Fragment>
                                                            ),
                                                        ) : <h3 className='text-center'>Crimes not found</h3>}
                                                    </div>
                                                </div>
                                                {pageCount ? (
                                                    <div className="card-footer pagination-wrapper card-footer-sticky">
                                                        <Pagination
                                                            pageCount={pageCount}
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            onPageChange={(
                                                                page: number,
                                                            ) => {
                                                                setCurrentPage(page)
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                            {offenderPopup && (
                                                <OffenderPopup
                                                    data={offenderPopup}
                                                    isOpen={!!offenderPopup}
                                                    onClose={() =>
                                                        setOffenderPopup(null)
                                                    }
                                                />
                                            )}
                                        </div>}
                                        {currentFilter === 'crimes' && <div
                                            className={`tab h-full active-tab`}
                                            data-id="list-view"
                                        >
                                            <div className="card h-full scroll-card">
                                                <div
                                                    className="card-header"
                                                    style={{ display: 'none' }}
                                                ></div>
                                                <div className="card-body">
                                                    <div className="crimes-list">
                                                        {crimes && crimes.length > 0 ? crimes?.map((crimel, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className={clsx("crimes-list-item relative", {
                                                                           'blur-item': chekIndexForPremiumMore(index)
                                                                        })}
                                                                    onClick={() => {
                                                                        if (chekIndexForPremiumMore(index)) {
                                                                            handleShowUpgradeModal()
                                                                        } else {
                                                                            setCrimesPopup(crimel)
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="crimes-list-item__group">
                                                                        <span className='crimes-list-item__date'>
                                                                            {crimel?.dateOfCrime.split(' ')?.[0]}
                                                                        </span>
                                                                        <span className='crimes-list-item__category'>{crimel?.category}</span>
                                                                        {crimel?.severity?.toUpperCase() === "SERIOUS" && <span className='ico'><WarningIcon /></span>}
                                                                    </div>
                                                                    {myLocation ? <div className="crimes-list-item__distance">
                                                                        {OffendersMap.getDistanceInMiles(myLocation, [+crimel?.lat, +crimel?.lon]).split(' ')[0]} mile(s)
                                                                    </div> : null}
                                                                </div>

                                                            </React.Fragment>
                                                        )
                                                        ) : <h3 className='text-center'>Crimes not found</h3>}

                                                    </div>
                                                </div>
                                                {/* {pageCount ? (
                                                    <div className="card-footer pagination-wrapper card-footer-sticky">
                                                        <Pagination
                                                            pageCount={pageCount}
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            onPageChange={(
                                                                page: number,
                                                            ) => {
                                                                setCurrentPage(page)
                                                            }}
                                                        />
                                                    </div>
                                                ) : null} */}
                                            </div>
                                            {crimesPopup && (
                                                <CrimesPopup
                                                    data={crimesPopup}
                                                    myLocation={myLocation}
                                                    isOpen={!!crimesPopup}
                                                    onClose={() =>
                                                        setCrimesPopup(null)
                                                    }
                                                />
                                            )}
                                        </div>}
                                    </>
                                )}
                                {activeTab === 'map_view' && (
                                    <div
                                        className={`tab h-full active-tab`}
                                        data-id="map-view"
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <MapOffender
                                                offenders={filteredOfferends}
                                                crimes={crimes || []}
                                                viewType={currentFilter}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : null
                        // <h2 className="heading font-500 mx-auto mt-5">No items found</h2>
                    }
                    {isLoading && (
                        <div className="offender-loading-container">
                            Loading...
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
