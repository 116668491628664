import type { SafeZoneTypes } from '../constants/saveZones'
import type { FAMILY_STATUS } from '../constants/statuses'

export enum PremiumStatus {
    Trial = 'trial',
    Subscription = 'subscription',
    Expired = 'expired',
}

export type Locales =
    | 'tr'
    | 'en'
    | 'cn'
    | 'de'
    | 'es'
    | 'fr'
    | 'in'
    | 'it'
    | 'jp'
    | 'kr'
    | 'nl'
    | 'pt'
    | 'sa'
    | 'ua'

export interface IUser {
    phoneNumber: string
    demo: boolean
    isSocialRegistration: boolean
    id: number
    fullName: string
    email: string
    phone: string
    phoneCountry: string
    notifications: string[]
    language: Locales | undefined
    photo: string
    offenders: string[]
    zipcodes: Record<string, any>
    locationSharing: boolean
    lastSeen: string
    premium:
        | PremiumStatus.Trial
        | PremiumStatus.Subscription
        | PremiumStatus.Trial
    stripeCustomerId: string | null
    createdAt: string
    familyAdmin: boolean
    familyMember: boolean
}

export interface IAlert {
    id: number
    name: string
    zip: string
    address: string
    range: number
}

// offender

export interface IOffenderAddress {
    street: string
    city: string
    state: string
    zipcode: string
    addressType: string
    location: {
        lat: number
        lon: number
    }
}

export interface IOffender {
    id: string
    offenderName: string
    age: number
    aliases: string
    address?: IOffenderAddress[]
    image_src: string
    childInvolved: Boolean
    rapist: Boolean
    violent: Boolean
}

export interface IUserFamily
    extends Pick<IUser, 'id' | 'fullName' | 'photo' | 'lastSeen'> {
    location: {
        latitude: number
        longitude: number
    }
}

export interface IFamilyMember {
    id: number
    fullName: string
    email: string
    photo: string
    phone: string
    phoneCountry: string
    status: FAMILY_STATUS.Accepted | FAMILY_STATUS.Pending
    member: IUserFamily | null
}
export interface IFamily {
    id: number
    adminPremium: boolean
    isAdmin: boolean
    members: IUserFamily[]
    invitations: IFamilyMember[]
    membersCount: number
    safeZonesCount: number
    name: string
}

export interface ISaveZone {
    id: number
    userId: number
    name: string
    type: SafeZoneTypes
    address: string
    latitude: number
    longitude: number
    radius: number
    createdAt: Date
    updatedAt: Date
}

export interface ISaveZoneRequest
    extends Omit<ISaveZone, 'id' | 'userId' | 'createdAt' | 'updatedAt'> {}

export type SubscriptionPeriod = 'weekly' | 'monthly' | 'yearly'

export type SubscriptionType = 'trial' | 'premium'

export interface ISubscription {
    id: number
    userId: number
    stripeSubscriptionId: string | null
    revenueCatSubscriptionId: string | null
    status: 'active' | 'canceled'
    startDate: string
    endDate: string
    cancelAt: string | null
    stripeCustomerId: string | null
    revenueCustomerId: string | null
    price: number | null
    type: SubscriptionPeriod
    createdAt: string
    updatedAt: string
    searchCount?: number | null
    alertsCount?: number | null
    alertsMax?: number | null
    searchMax?: number | null
}

export interface IArestInformations {
    success: boolean
    charges: ICharge[] | null
    link: string | null
    height: number | null
    weight: number | null
    sex: string | null
    message: string | null
    dob: string | null
    createDate: string | null
    modifyDate: string | null
    OffenderId: string | null
}
export interface ICharge {
    charge: string | null
    date_convicted: string | null
    court: string | null
    victim_age: string | null
    charge_details: string | null
    createDate: Date | null
    modifyDate: Date | null
}
