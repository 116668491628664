import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useNavigate, useSearchParams } from 'react-router-dom'

import PlusIcon from '../../assets/img/ico-calc-plus.svg?react'
import NextIcon from '../../assets/img/ico-pagination--next.svg?react'
import PrevIcon from '../../assets/img/ico-pagination--prev.svg?react'
import RemoveIcon from '../../assets/img/ico-trash.svg?react'
import Button from '../../compoments/Button'
import Image from '../../compoments/Image'
import {
    useDeleteFamilyMutation,
    useGetAllFamiliesQuery,
} from '../../services/family'
import { getMediaUrl } from '../../utils/helpers'

import AddFamilyModal from './components/AddFamilyModal'

const FamilyPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation('members')
    const [searchParams, setSearchParams] = useSearchParams()
    const { data: familyData, isLoading } = useGetAllFamiliesQuery(
        searchParams.toString(),
    )
    const [deleteFamily] = useDeleteFamilyMutation()
    const [addFamilyModal, setAddFamilyModal] = useState<boolean>(false)

    const familyModalHandler = () => {
        setAddFamilyModal((prev) => !prev)
    }

    const handlePageChange = (page: number) => {
        setSearchParams((prev) => {
            prev.set('page', String(page))
            return prev
        })
    }

    if (isLoading || !familyData) {
        return null
    }

    return (
        <>
            <main className="content">
                <div className="container h-full">
                    <div className="catalog-page section-fullscreen headers-similars">
                        <div className="catalog-page-content">
                            <div className="card card-border h-full scroll-card">
                                <div className="card-header border-none header-similar card-header-catalog">
                                    <div className="card-header--item gap-12">
                                        <h1 className="heading font-500">
                                            {t('families.family')}
                                        </h1>
                                    </div>
                                    <div className="card-header--item">
                                        <Button
                                            onClick={familyModalHandler}
                                            className="btn btn--primary btn--lg rounded-full"
                                        >
                                            <PlusIcon />
                                            {t('families.create')}
                                        </Button>
                                    </div>
                                </div>
                                <div className="card-body overflow-hidden">
                                    <div className="table-wrapper scroll-styler">
                                        <table
                                            className="table"
                                            cellPadding="0"
                                            cellSpacing="0"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>{t('families.family')}</th>
                                                    <th>{t('families.members')}</th>
                                                    <th>{t('families.safe_zones')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {familyData?.data?.length
                                                    ? familyData.data.map(
                                                          (family, index) => (
                                                              <tr key={index}>
                                                                  <td>
                                                                      <div className="flex gap-12 items-center">
                                                                          {family
                                                                              .members
                                                                              ?.length ===
                                                                              1 && (
                                                                              <div className="ico ico--sm family-link__icon rounded-full relative overflow-hidden">
                                                                                  <Image
                                                                                      src={getMediaUrl(
                                                                                          family
                                                                                              ?.members?.[0]
                                                                                              ?.photo,
                                                                                      )}
                                                                                  />
                                                                              </div>
                                                                          )}
                                                                          {family
                                                                              .members
                                                                              ?.length >=
                                                                              2 && (
                                                                              <div className="family-group">
                                                                                  {family.members?.map(
                                                                                      (
                                                                                          member,
                                                                                          index,
                                                                                      ) => (
                                                                                          <div
                                                                                              key={
                                                                                                  member.id
                                                                                              }
                                                                                              className={clsx(
                                                                                                  'family-group-item',
                                                                                                  {
                                                                                                      'pos-top':
                                                                                                          index ===
                                                                                                          2,
                                                                                                      'pos-left':
                                                                                                          index ===
                                                                                                          1,
                                                                                                      'pos-right':
                                                                                                          index ===
                                                                                                          0,
                                                                                                  },
                                                                                              )}
                                                                                          >
                                                                                              <Image
                                                                                                  src={getMediaUrl(
                                                                                                      member.photo,
                                                                                                  )}
                                                                                              />
                                                                                          </div>
                                                                                      ),
                                                                                  )}
                                                                              </div>
                                                                          )}
                                                                          {
                                                                              family.name
                                                                          }
                                                                      </div>
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          family.membersCount
                                                                      }{' '}
                                                                      members
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          family.safeZonesCount
                                                                      }{' '}
                                                                      safe zones
                                                                  </td>
                                                                  <td className="flex w-full justify-end">
                                                                      <div className="flex gap-12 max-w-max">
                                                                          <Button
                                                                              onClick={() =>
                                                                                  navigate(
                                                                                      `/family-members/${family.id}`,
                                                                                  )
                                                                              }
                                                                              className="btn btn--outline-secondary-2 btn--xs rounded-full"
                                                                          >
                                                                              {t('families.show_more')}
                                                                          </Button>
                                                                          <div
                                                                              role="button"
                                                                              tabIndex={
                                                                                  0
                                                                              }
                                                                              onClick={async () =>
                                                                                  deleteFamily(
                                                                                      family.id,
                                                                                  )
                                                                              }
                                                                              onKeyDown={async (
                                                                                  e,
                                                                              ) =>
                                                                                  e.key ===
                                                                                      'Enter' &&
                                                                                  deleteFamily(
                                                                                      family.id,
                                                                                  )
                                                                              }
                                                                              className="flex items-center btn--outline-danger px-3 rounded-full pointer"
                                                                          >
                                                                              <RemoveIcon
                                                                                  height={
                                                                                      20
                                                                                  }
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                  </td>
                                                              </tr>
                                                          ),
                                                      )
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    {familyData?.pagination &&
                                        familyData.pagination?.maxPages >=
                                            2 && (
                                            <ReactPaginate
                                                breakLabel="..."
                                                previousLabel={<PrevIcon />}
                                                nextLabel={<NextIcon />}
                                                previousClassName="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
                                                nextClassName="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
                                                pageCount={
                                                    familyData?.pagination
                                                        ?.maxPages || 1
                                                }
                                                containerClassName="pagination"
                                                pageClassName="pagination-item"
                                                pageLinkClassName="btn btn--square btn--sm rounded-full btn-pagination"
                                                activeLinkClassName="--active"
                                                pageRangeDisplayed={5}
                                                onPageChange={(selectedItem) =>
                                                    handlePageChange(
                                                        selectedItem.selected +
                                                            1,
                                                    )
                                                }
                                                renderOnZeroPageCount={null}
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {addFamilyModal && (
                    <AddFamilyModal
                        isOpen={addFamilyModal}
                        onClose={familyModalHandler}
                    />
                )}
            </main>
        </>
    )
}

export default FamilyPage
