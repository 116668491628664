/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg'
import NotificationPrimaryIcon from '../../../assets/img/ico-notification-1.svg?react'
import NotificationSecondaryIcon from '../../../assets/img/ico-notification-2.svg?react'
import ViolentIcon from '../../../assets/img/violent-icon.svg?react'
import BabyIcon from '../../../assets/img/baby-icon.svg?react'
import DangerIcon from '../../../assets/img/triengle.svg?react'
import { useGetMeQuery } from '../../../services/auth'
import { useAddUserMutation, useDeleteUserMutation } from '../../../services/notifications'
import type { IOffender } from '../../../types'
import { getExternalMediaUrl } from '../../../utils/helpers'

const CardPerson = ({
    data,
    selectedOffenders,
}: {
    data?: IOffender
    selectedOffenders: string[]
}) => {
    const [isNotify, setIsNotify] = useState(false)

    const [triggerAddUser] = useAddUserMutation()
    const [triggerDeleteUser] = useDeleteUserMutation()
    const { refetch } = useGetMeQuery()

    useEffect(() => {
        if (selectedOffenders.includes(data?.id as string)) {
            setIsNotify(true)
        } else {
            setIsNotify(false)
        }
    }, [selectedOffenders])

    const handleChange = async () => {
        if (!data?.id) return

        try {
            setIsNotify(true)
            let res
            if (isNotify) {
                res = await triggerDeleteUser({
                    offenderDocIds: [data.id],
                }).unwrap()
            } else {
                res = await triggerAddUser({
                    offenderDocIds: [data.id],
                }).unwrap()
            }


            if (res.success) {
                // toast.success('Offender Added Successfully')
                await refetch()
            } else {
                setIsNotify(false)
                toast.error(
                    res.message || 'Offender notifications added successfully',
                )
            }
        } catch (e) {
            setIsNotify(false)
            toast.error('Please Login / Sign Up')
        }
    }

    const address = data?.address?.[0]

    if (!data) return null

    return (
        <div className="card card-custom-1 h-full">
            <div className="wrapper-image card-bg--image">
                <img
                    src={getExternalMediaUrl(data.image_src)}
                    onError={(e) => {
                        e.currentTarget.src = AgeRangeIcon
                    }}
                    alt="card-photo"
                />
                <div className="card-actions">
                    <label className="btn" onClick={(e) => e.stopPropagation()}>
                        <input
                            // disabled={isNotify}
                            type="checkbox"
                            hidden
                            checked={isNotify}
                            onChange={handleChange}
                        />
                        <div className="btn btn--square btn--md rounded-full btn-check--likely">
                            <span className="ico">
                                <NotificationPrimaryIcon />
                            </span>
                            <span className="ico">
                                <NotificationSecondaryIcon />
                            </span>
                        </div>
                    </label>
                </div>
            </div>
            <div className="card-body card-person">
                <div className="heading-block">
                    <span className="text--lg heading">
                        {data.offenderName}
                    </span>
                    <p className="default-text text--sm heading-desk">
                        {address?.street}
                    </p>
                    <div className='flex items-start justify-between w-full'>
                        {[data.childInvolved, data.rapist, data.violent].filter(Boolean).length > 0 && <div className="flex flex-wrap items-center gap-5">
                            {data.violent && <div className="offender-status-type">
                                <span className='ico'>
                                    <ViolentIcon/>
                                </span>
                                <span>Violent</span>
                            </div>}
                            {data.rapist && <div className="offender-status-type danger">
                                <span className='ico'>
                                    <DangerIcon/>
                                </span>
                                <span>Rape</span>
                            </div>}
                            {data.childInvolved && <div className="offender-status-type warning">
                                <span className='ico'>
                                    <BabyIcon/>
                                </span>
                                <span>Child Involved</span>
                            </div>}
                        </div>}
                        <div className='bth btn--xs btn--square btn--ligth rounded-full ml-auto'>
                            <span className='ico'>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.5 10.5L10.5 3.5" stroke="#253237" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.8125 3.5H10.5V9.1875" stroke="#253237" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPerson
