import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect, useRef, useState } from 'react'
import { Map, Marker, NavigationControl } from 'react-map-gl'
import UserPremiumIcon from '../../../assets/img/premium-user-map-icon.png'
import CrimesPremiumIcon from '../../../assets/img/crimes-premium-icon.png'
import type { IOffender } from '../../../types'
import { getExternalMediaUrl } from '../../../utils/helpers'

import OffenderPopup from './OffenderPopup'
import { Crimes } from '../../../services/types'
import clsx from 'clsx'
import { useAppSelector } from '../../../hooks/redux'
import { getIsExpired } from '../../../features/auth/authSlice'
import { useModal } from '../../../hooks/useModal'
import { maxShowItemsForExpiretUser } from '../../../constants/statuses'
const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY
mapboxgl.accessToken = mapBoxToken

interface MarkerData {
    lngLat: [number, number]
    title: string
    distanceTime: number
    photo: string
    offender: IOffender
}

const MapOffender: React.FC<{ offenders: IOffender[], crimes: Crimes[], viewType: string }> = ({ offenders, crimes, viewType }) => {
    const [offenderPopup, setOffenderPopup] = useState<IOffender | null>(null)
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const [userLocation, setUserLocation] = useState<[number, number]>([-74.006, 40.7128])
    const [positionIsReady, setPositionIsReady] = useState(false)
    const [mapView, setMapView] = useState({
        longitude: -74.006,
        latitude: 40.7128,
        zoom: 14
    })
    const isExpired = useAppSelector(getIsExpired)

    const { showModal } = useModal()

    const markers = offenders
        .filter((offender) => offender.address !== null)
        .map((el) => {
            const address = el.address?.[0].location as {
                lat: number
                lon: number
            }

            return {
                lngLat: [+address.lon, +address.lat] as [number, number],
                title: 'title',
                distanceTime: 10,
                photo: getExternalMediaUrl(el.image_src),
                offender: el,
            }
        })
    const crimesMarkers = crimes?.filter(item => item.lat && item.lon) || []
    useEffect(() => {
        // Получаем текущую геопозицию пользователя
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords
                setMapView(prev => ({
                    ...prev,
                    longitude: longitude,
                    latitude: latitude,
                }))
                setUserLocation([longitude, latitude])
                setPositionIsReady(true)
            },
            (error) => {
                console.error('Error getting user location:', error)
                // setUserLocation(null) // Если не удалось получить координаты, оставляем null
            },
        )
    }, [])

    const handleShowUpgradeModal = () => {
        showModal('upgrade')
    }

    const chekIndexForPremium = (index: number) => {
        return isExpired && (index + 1 > maxShowItemsForExpiretUser)
    }
    if (!positionIsReady) return null
    return (
        <>
            {/* <div
                ref={mapContainerRef}
                id="map"
                className="map-container"
                style={{ width: '100%', height: '100%' }}
            /> */}
            <div
                className="map-container relative"
                style={{ width: '100%', height: '100%' }}
            >
                {isExpired && <div className="premium-block-map">
                    <p>Only 3 items unlocked. Upgrade to see it all with Premium!</p>
                    <div className="flex flex-col">
                        <button
                            onClick={handleShowUpgradeModal}
                            type="button"
                            className="btn btn--lg  btn--gradient rounded-full"
                        >
                            <span className="ico">
                                <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.8083 12.4319L20.0191 10.1989C20.1841 8.44621 20.2666 7.56896 19.9659 7.20687C19.8901 7.11277 19.7963 7.03461 19.6901 6.97687C19.584 6.91913 19.4674 6.88297 19.3472 6.87046C18.9108 6.83196 18.3636 7.45529 17.2682 8.70196C16.7017 9.34729 16.4184 9.66904 16.1031 9.71946C15.927 9.7471 15.7466 9.71822 15.5879 9.63696C15.2955 9.49029 15.1021 9.09154 14.7125 8.29496L12.6619 4.09296C11.9268 2.58687 11.5592 1.83337 11 1.83337C10.4408 1.83337 10.0733 2.58687 9.33809 4.09296L7.2875 8.29496C6.89884 9.09246 6.7045 9.49029 6.41209 9.63696C6.25321 9.71762 6.07304 9.74647 5.89692 9.71946C5.58159 9.66904 5.29834 9.34729 4.73184 8.70196C3.63642 7.45529 3.08917 6.83196 2.65284 6.87046C2.53261 6.88297 2.41605 6.91913 2.30986 6.97687C2.20367 7.03461 2.10995 7.11277 2.03409 7.20687C1.73434 7.56896 1.81684 8.44621 1.98184 10.1989L2.19175 12.4319C2.53825 16.1095 2.71059 17.9493 3.79592 19.0575C4.8785 20.1667 6.50284 20.1667 9.75334 20.1667H12.2458C15.4953 20.1667 17.1197 20.1667 18.2041 19.0575C19.2885 17.9484 19.4618 16.1095 19.8083 12.4319Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <span>Premium</span>
                        </button>
                        <p className='premium-block-map-below-btn'>Upgrade Now to Unlock!</p>
                    </div>
                </div>}
                <Map
                    {...mapView}

                    onMove={({ viewState }) => setMapView(prev => ({
                        latitude: viewState.latitude,
                        longitude: viewState.longitude,
                        zoom: viewState.zoom,
                    }))}
                    initialViewState={mapView}
                    accessToken={mapBoxToken}
                    style={{ width: '100%', height: '100%' }}
                    mapStyle="mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0"
                >
                    <Marker
                        longitude={userLocation[0]}
                        latitude={userLocation[1]}
                    >
                        <div className="home-marker">
                            <div className="home-marker__inner">
                                <div className="ico">
                                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_21_2302)">
                                            <path d="M21.125 17.875H19.75V11.6875L19.9511 11.8886C20.0803 12.0176 20.2555 12.09 20.4381 12.0898C20.6207 12.0896 20.7958 12.0169 20.9248 11.8877C21.0538 11.7585 21.1262 11.5833 21.126 11.4007C21.1258 11.2181 21.0531 11.043 20.9239 10.914L12.472 2.46467C12.2141 2.207 11.8645 2.06226 11.5 2.06226C11.1355 2.06226 10.7859 2.207 10.528 2.46467L2.07609 10.914C1.9472 11.043 1.87484 11.218 1.87492 11.4003C1.875 11.5827 1.94752 11.7575 2.07652 11.8864C2.20553 12.0153 2.38045 12.0877 2.5628 12.0876C2.74516 12.0875 2.92002 12.015 3.04891 11.886L3.25 11.6875V17.875H1.875C1.69266 17.875 1.5178 17.9474 1.38886 18.0763C1.25993 18.2053 1.1875 18.3801 1.1875 18.5625C1.1875 18.7448 1.25993 18.9197 1.38886 19.0486C1.5178 19.1775 1.69266 19.25 1.875 19.25H21.125C21.3073 19.25 21.4822 19.1775 21.6111 19.0486C21.7401 18.9197 21.8125 18.7448 21.8125 18.5625C21.8125 18.3801 21.7401 18.2053 21.6111 18.0763C21.4822 17.9474 21.3073 17.875 21.125 17.875ZM13.5625 17.875H9.4375V13.75C9.4375 13.6588 9.47372 13.5714 9.53818 13.5069C9.60265 13.4424 9.69008 13.4062 9.78125 13.4062H13.2188C13.3099 13.4062 13.3974 13.4424 13.4618 13.5069C13.5263 13.5714 13.5625 13.6588 13.5625 13.75V17.875Z" fill="#0A3313" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_21_2302">
                                                <rect width="22" height="22" fill="white" transform="translate(0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </Marker>
                    {viewType !== 'crimes' && markers.map((mark, index) => (
                        <Marker
                            longitude={mark.lngLat[0]}
                            latitude={mark.lngLat[1]}
                            key={index}
                            onClick={() => {
                                if (chekIndexForPremium(index)) {
                                    handleShowUpgradeModal()
                                } else {
                                    setOffenderPopup(mark.offender)
                                }

                            }}
                        >
                            {chekIndexForPremium(index) ? <img src={UserPremiumIcon} alt="" /> : <div className="person-marker">
                                <div>
                                    <img src={mark.photo} alt="" />
                                </div>
                            </div>}
                        </Marker>
                    ))}
                    {viewType !== 'sex_offenders' && crimesMarkers.map((mark, index) => (
                        <Marker
                            key={index}
                            longitude={mark.lon}
                            latitude={mark.lat}

                        >
                            {chekIndexForPremium(index)
                                ? <img src={CrimesPremiumIcon} alt="" />
                                : <div className={clsx('crimes-marker', {
                                    'danger': mark?.severity?.toUpperCase() === "SERIOUS",
                                    'warning': mark?.severity?.toUpperCase() === "MEDIUM",
                                })}> </div>
                            }
                        </Marker>
                    ))}
                    <NavigationControl position='bottom-right' />
                </Map>
            </div>

            {offenderPopup && (
                <OffenderPopup
                    data={offenderPopup}
                    isOpen={!!offenderPopup}
                    onClose={() => setOffenderPopup(null)}
                />
            )}
        </>
    )
}

export default MapOffender
