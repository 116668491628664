// eslint-disable-next-line import/named
import { distance, point } from '@turf/turf'
import type { Marker } from 'mapbox-gl'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg'
import SaveZone1 from '../../../assets/img/icon-safe-zone-1.svg'
import SaveZone2 from '../../../assets/img/icon-safe-zone-2.svg'
import SaveZone3 from '../../../assets/img/icon-safe-zone-3.svg'
import { getUser } from '../../../features/auth/authSlice'
import {
    useLazyGetLocationHistoryQuery,
    useLazyGetOffendersMapQuery,
} from '../../../services/family'
import type { IFamilyMember, IOffender, ISaveZone } from '../../../types'
import { getExternalMediaUrl, getMediaUrl } from '../../../utils/helpers'
import OffenderPopup from '../../HomePage/components/OffenderPopup'

import type { VisibleFamilyMapItems } from './FamilyOffenders'

import { format } from 'date-fns'

export class OffendersMap {
    constructor() {}
    static createPersonMarkerElement(
        photo: string,
        label?: string,
        type: 'default' | 'member' | 'offender' = 'default',
    ): Marker {
        const el = document.createElement('div')
        const imagePath = photo
        el.innerHTML = `
      <div class="person-marker">
        <div>
          <img src="${imagePath}" onerror="this.onerror=null;this.src='${AgeRangeIcon}'" alt="" />
        </div>
        ${label ? `<div class="person-marker__label ${type}">${label}</div>` : ''}
      </div>
    `
        return new mapboxgl.Marker(el)
    }
    static createHomeMarkerElement(zone: string): Marker {
        const el = document.createElement('div')
        el.innerHTML = `
          <div class="home-marker">
            <div class="home-marker__inner">
              <div class="ico">
                 <img src="${zone}" onerror="this.onerror=null;this.src='${AgeRangeIcon}'" style='height: auto' alt="" />
              </div>
            </div>
          </div>
        `
        return new mapboxgl.Marker(el)
    }
    static normalizeOffendersData(data: IOffender[]): {
        lngLat: [number, number]
        title: string
        distanceTime: number
        photo: string
        offender: IOffender
    }[] {
        return data
            .filter((offender) => offender.address !== null)
            .map((el) => {
                const address = el?.address?.[0].location as {
                    lat: number
                    lon: number
                }
                return {
                    lngLat: [+address.lon, +address.lat] as [number, number],
                    title: 'title',
                    distanceTime: 10,
                    photo: el.image_src,
                    offender: el,
                }
            })
    }
    static getDistanceInMiles(
        point1: [number, number],
        point2: [number, number],
    ): string {
        const from = point(point1)
        const to = point(point2)

        const distInMiles = distance(from, to, { units: 'miles' }).toFixed(0)

        if (parseFloat(distInMiles) <= 1) {
            return distance(from, to, { units: 'meters' }).toFixed(0) + ' m'
        }

        return distInMiles + ' mi'
    }
}

const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY
mapboxgl.accessToken = mapBoxToken

const zoneIcons = {
    GraduationCap: SaveZone2,
    HouseLine: SaveZone1,
    Bed: SaveZone3,
}

interface IMap {
    users: IFamilyMember[]
    selectedUser: IFamilyMember | null
    setSelectedUser: (value: IFamilyMember | null) => void
    saveZones: ISaveZone[]
    visibleItems: VisibleFamilyMapItems
}

const Map = ({
    users,
    selectedUser,
    setSelectedUser,
    saveZones,
    visibleItems,
}: IMap) => {
    const [getOffenders, { data: offendersData }] =
        useLazyGetOffendersMapQuery()
    const [getLocationHistory] = useLazyGetLocationHistoryQuery()
    const [offenderPopup, setOffenderPopup] = useState<IOffender | null>(null)
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const markersRef = useRef<{
        offenders: mapboxgl.Marker[]
        members: mapboxgl.Marker[]
        saveZones: mapboxgl.Marker[]
        me: mapboxgl.Marker | null
    }>({
        offenders: [],
        members: [],
        saveZones: [],
        me: null,
    })
    const pointMarkersRef = useRef<mapboxgl.Marker[]>([])
    const [myLocation, setMyLocation] = useState<[number, number] | null>(null)
    // const [myLocation, setMyLocation] = useState<[number, number]>([
    //   -118.248, 34.064,
    // ]) // TEST
    const [locationRequested, setLocationRequested] = useState(false)
    const loadOffenders = (map: mapboxgl.Map) => {
        if (!visibleItems.offenders || !map || !myLocation) return
        const [lng, lat] = myLocation

        // const center = map.getCenter()
        // const zoom = map.getZoom()
        // const maxZoom = 22
        // const maxRadius = 12
        // const radius = Math.max(
        //   0,
        //   maxRadius - Math.floor((zoom / maxZoom) * maxRadius),
        // )

        const options = { lat, lng, radius: 1 }
        getOffenders(options)
    }

    const user = useSelector(getUser)

    const [mapInstanse, setMapInstanse] = useState<mapboxgl.Map>()

    const toggleMarkersVisibility = () => {
        if (selectedUser) {
            renderPoints()
        } else {
            removePointsAndLine()
        }
    }

    const renderPoints = async () => {
        const memberId = selectedUser?.member?.id
        if (!memberId) return

        const today = new Date()
        today.setDate(today.getDate() - 1)
        const startDate = today.toISOString()
        const endDate = new Date().toISOString()
        const locationHistoryData = await getLocationHistory({
            id: memberId,
            startDate,
            endDate,
        }).unwrap()

        const locationHistory = locationHistoryData?.data
        if (!locationHistory?.length) return

        const points = locationHistory.map((point) => ({
            coordinates: [+point.longitude, +point.latitude],
            date: point.createdAt,
        }))
        const poinstMarkers = points.map((coord) => {
            const popup = new mapboxgl.Popup({
                offset: 10,
                closeButton: false,
                className: 'coord-popup',
            }).setText(format(coord.date, 'dd.MM.yyyy, HH:mm'))
            const el = document.createElement('div')
            el.innerHTML = `
                <div class="dot-marker"></div>
            `

            const marker = new mapboxgl.Marker(el)
                .setLngLat(coord.coordinates as [number, number])
                .setPopup(popup)
                .addTo(mapInstanse!)
            return marker
        })
        pointMarkersRef.current = poinstMarkers
        mapInstanse!.addSource('route', {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: points.map((point) => point.coordinates),
                },
                properties: {
                    name: 'My Route',
                    description: 'This is a route description',
                    createdAt: new Date().toISOString(),
                },
            },
        })

        mapInstanse!.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': '#F41658', // Цвет линии
                'line-width': 1, // Толщина линии
            },
        })
    }
    const removePointsAndLine = () => {
        // Удаляем все маркеры
        pointMarkersRef.current.forEach((marker) => marker.remove())
        pointMarkersRef.current = []

        // Удаляем линию, если она существует
        if (mapInstanse?.getLayer('route')) {
            mapInstanse.removeLayer('route')
        }
        if (mapInstanse?.getSource('route')) {
            mapInstanse.removeSource('route')
        }
    }

    useEffect(() => {
        if (!visibleItems.members) {
            markersRef.current.members.forEach((marker) => marker.remove())
        }
        if (mapInstanse && users && visibleItems.members) {
            markersRef.current.members.forEach((marker) => marker.remove())
            const filteredUsers = selectedUser ? [selectedUser] : users

            const markers = filteredUsers
                .filter((user) => !!user?.member?.location)
                .map((markerData) => {
                    const { latitude, longitude } = markerData.member!.location!
                    const distance = myLocation
                        ? OffendersMap.getDistanceInMiles(myLocation, [
                              +longitude,
                              +latitude,
                          ])
                        : undefined
                    const marker = OffendersMap.createPersonMarkerElement(
                        getMediaUrl(markerData?.photo),
                        distance,
                        'member',
                    )
                        .setLngLat([+longitude, +latitude])
                        .addTo(mapInstanse)

                    marker.getElement().addEventListener('click', () => {
                        setSelectedUser(selectedUser ? null : markerData)
                    })
                    return marker
                })

            toggleMarkersVisibility()
            markersRef.current.members = markers
        }
    }, [users, mapInstanse, visibleItems.members, myLocation])

    useEffect(() => {
        const offenders = offendersData?.offenders
        if (!visibleItems.offenders) {
            markersRef.current.offenders.forEach((marker) => marker.remove())
        }
        if (mapInstanse && offenders?.length && visibleItems.offenders) {
            markersRef.current.offenders.forEach((marker) => marker.remove())

            const markers = OffendersMap.normalizeOffendersData(offenders).map(
                (offender) => {
                    const distance = myLocation
                        ? OffendersMap.getDistanceInMiles(
                              myLocation,
                              offender.lngLat,
                          )
                        : undefined
                    const marker = OffendersMap.createPersonMarkerElement(
                        getExternalMediaUrl(offender.photo),
                        distance,
                        'offender',
                    )
                        .setLngLat(offender.lngLat)
                        .addTo(mapInstanse)
                    marker.getElement().addEventListener('click', () => {
                        setOffenderPopup(offender.offender)
                    })
                    return marker
                },
            )

            toggleMarkersVisibility()

            markersRef.current.offenders = markers
        }
    }, [offendersData, mapInstanse, visibleItems.offenders, myLocation])

    useEffect(() => {
        if (mapInstanse && user && myLocation) {
            markersRef.current.offenders.forEach((marker) => marker.remove())

            const marker = OffendersMap.createPersonMarkerElement(
                getMediaUrl(user.photo),
                'me',
            )
                .setLngLat(myLocation)
                .addTo(mapInstanse)

            toggleMarkersVisibility()

            markersRef.current.me = marker
        }
    }, [mapInstanse, user, myLocation])

    useEffect(() => {
        if (mapInstanse && saveZones.length) {
            markersRef.current.saveZones.forEach((marker) => marker.remove())

            const markers = saveZones.map((zone) => {
                return OffendersMap.createHomeMarkerElement(
                    zoneIcons[zone.type as keyof typeof zoneIcons],
                )
                    .setLngLat([zone.longitude, zone.latitude])
                    .addTo(mapInstanse)
            })

            markersRef.current.saveZones = markers
        }
    }, [mapInstanse, saveZones])

    useEffect(() => {
        if (!mapContainerRef.current || !locationRequested) return

        const defaultLocation = [-74.006, 40.7128] as [number, number]

        const map = new mapboxgl.Map({
            container: mapContainerRef.current!,
            style: 'mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0',
            center: myLocation || defaultLocation,
            zoom: 12,
        })

        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')

        map.on('load', () => {
            map.resize()
        })
        // map.on('zoomend', () => {
        //   loadOffenders(map)
        // })

        setMapInstanse(map)
        loadOffenders(map)

        return () => {
            map.remove()
            setMapInstanse(undefined)
        }
    }, [mapContainerRef, locationRequested])

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setMyLocation([
                        position.coords.longitude,
                        position.coords.latitude,
                    ])
                    setLocationRequested(true)
                },
                (error) => {
                    toast.warn(
                        `Geolocation access denied. Please enable geolocation in your browser settings`,
                    )
                    setLocationRequested(true)
                    console.log(error.message)
                },
            )
        } else {
            setLocationRequested(true)
            toast.warn('Geolocation is not supported by your browser.')
            console.log('Geolocation is not supported by your browser.')
        }
    }, [])

    return (
        <>
            {!mapContainerRef.current && (
                <p className="text-center color-text mt-5">
                    Please enable geolocation in your browser settings
                </p>
            )}
            <div
                ref={mapContainerRef}
                className="map-container markers-blue-type"
                style={{ width: '100%', height: '100%' }}
            />
            {offenderPopup && (
                <OffenderPopup
                    data={offenderPopup}
                    isOpen={!!offenderPopup}
                    onClose={() => setOffenderPopup(null)}
                />
            )}
        </>
    )
}

export default Map
