import React from 'react'
import Modal from '../../../compoments/Modal'
import { Crimes } from '../../../services/types'
import { Map, Marker } from 'react-map-gl'

import CrimesMarkerIcon from '../../../assets/img/crimes-marker-icon.svg?react'
import { OffendersMap } from "../../FamilyPage/components/Map";

const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY
interface CrimesPopupProps {
    myLocation: [number, number] | null
    data: Crimes
    isOpen: boolean
    onClose: () => void
}
const CrimesPopup = ({ data, isOpen, myLocation, onClose }: CrimesPopupProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            modalClassNames={'modal-card'}
            modalSize={'modal__dialog--410'}
        >
            <div className="modal__header">
                <div className="flex flex-col gap-10">
                    <div className="crimes-list-item__group">
                        <span className='crimes-list-item__date'>
                            {data.dateOfCrime.split(' ')?.[0]}
                        </span>
                        <span className='crimes-list-item__category'>{data.category}</span>
                    </div>
                    {data?.severity?.toUpperCase() === "SERIOUS" && <span className='flex items-center gap-10'>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="8" height="8" rx="4" transform="matrix(-1 0 0 1 8 0)" fill="#F41658" />
                        </svg>
                        Serious Crime
                    </span>
                    }
                </div>
                {myLocation ? <div className="crimes-list-item__distance">
                    {OffendersMap.getDistanceInMiles(myLocation, [+data?.lat, +data?.lon]).split(' ')[0]} mile(s)
                </div> : null}
            </div>
            <div className="modal__body">
                <div className="map-crimes-container mb-22">
                    <Map
                        accessToken={mapBoxToken}
                        initialViewState={{
                            longitude: data?.lon,
                            latitude: data?.lat,
                            zoom: 14
                        }}
                        style={{width: '100%', height: 160}}
                        mapStyle="mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0"
                    >
                        <Marker longitude={data?.lon} latitude={data?.lat}>
                            <CrimesMarkerIcon/>
                        </Marker>
                    </Map>
                </div>
                <div className="form-group gap-10 mb-22">
                    <label className="label">Date of Incident</label>
                    <div className="info-block">
                        {data.dateOfCrime || 'Not Specified'}
                    </div>
                </div>
                <div className="form-group gap-10 mb-22">
                    <label className="label">Incident</label>
                    <div className="info-block">
                        {data.category || 'Not Specified'}
                    </div>
                </div>
                <div className="form-group gap-10 mb-22">
                    <label className="label">Address</label>
                    <div className="info-block">
                        {data.address || 'Not Specified'}
                    </div>
                </div>
                <div className="form-group gap-10 mb-22">
                    <label className="label">Description</label>
                    <div className="info-block">
                        {data.description || 'Not Specified'}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CrimesPopup
