import type { FAMILY_STATUS } from '../constants/statuses'
import type {
    IFamily,
    IFamilyMember,
    ISaveZone,
    ISaveZoneRequest,
} from '../types'
import { IOffender } from '../types'

import { baseApi } from './base'
import type {
    FamilyCreateResponse,
    FamilyMemberUpdateResponse,
    IResponseType,
    LocationHistory,
    OffendersData,
    ResponseWidthOutDataType, Trip, TripsResponse
} from "./types";

const authBase = 'api/family'

export const familyApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getFamily: builder.query<IResponseType<IFamilyMember[]>, void>({
            query: () => `${authBase}`,
            providesTags: ['family'],
        }),
        getFamilyById: builder.query<IResponseType<IFamily>, string>({
            query: (id: string) => `${authBase}/${id}`,
            providesTags: ['family'],
        }),
        getAllFamilies: builder.query<IResponseType<IFamily[]>, string | void>({
            query: (query = '') => `${authBase}?${query}`,
            providesTags: ['families'],
        }),
        createFamily: builder.mutation<
            IResponseType<FamilyCreateResponse>,
            FormData
        >({
            query: (body) => ({
                url: `${authBase}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['families'],
        }),

        deleteFamily: builder.mutation<ResponseWidthOutDataType, number>({
            query: (id) => ({
                url: `${authBase}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['families'],
        }),
        getOffendersMap: builder.query<
            OffendersData,
            {
                lat: number
                lng: number
                radius: number
            }
        >({
            query: ({ lat, lng, radius }) =>
                `${authBase}/offender-map?latitude=${lat}&longitude=${lng}&radius=${radius}`,
        }),
        inviteFamily: builder.mutation<
            IResponseType<FamilyMemberUpdateResponse>,
            { id?: string; body?: FormData }
        >({
            query: ({ id, body }) => ({
                url: `${authBase}/invite/${id}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['family'],
        }),
        getSaveZones: builder.query<
            IResponseType<ISaveZone[]>,
            { id?: string; qwery?: string }
        >({
            query: ({ id, qwery = '' }) =>
                `${authBase}/safe-zones/${id}?${qwery}`,
            providesTags: ['saveZones'],
        }),
        getLocationHistory: builder.query<
            IResponseType<LocationHistory[]>,
            { id: number; startDate: string; endDate: string }
        >({
            query: ({ id, startDate, endDate }) =>
                `${authBase}/locations/${id}?startDate=${startDate}&endDate=${endDate}`,
            providesTags: ['saveZones'],
        }),
        getMemberTrips: builder.query<
            IResponseType<Trip[]>,
            { id: number; startDate: string; endDate: string }
        >({
            query: ({ id, startDate, endDate }) =>
                `/api/trips/${id}?startDate=${startDate}&endDate=${endDate}`,
        }),
        createSaveZone: builder.mutation<
            IResponseType<ISaveZone>,
            { id?: string; body: ISaveZoneRequest }
        >({
            query: ({ id, body }) => ({
                url: `${authBase}/safe-zones/${id}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['saveZones'],
        }),
        updateSaveZone: builder.mutation<
            IResponseType<ISaveZone>,
            { id: number; body: ISaveZoneRequest }
        >({
            query: ({ id, body }) => ({
                url: `${authBase}/safe-zones/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['saveZones'],
        }),
        manageInviteStatus: builder.mutation<
            IResponseType<IFamilyMember>,
            {
                id: number
                body: {
                    status: FAMILY_STATUS.Accepted | FAMILY_STATUS.Rejected
                }
            }
        >({
            query: ({ id, body }) => ({
                url: `${authBase}/invite/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['notifications'],
        }),
        updateFamilyMember: builder.mutation<
            IResponseType<IFamilyMember>,
            { id: number; body: FormData }
        >({
            query: ({ id, body }) => ({
                url: `${authBase}/members/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['family'],
        }),
        deleteFamilyMember: builder.mutation<ResponseWidthOutDataType, number>({
            query: (id) => ({
                url: `${authBase}/members/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['family'],
        }),
        deleteSaveZone: builder.mutation<ResponseWidthOutDataType, number>({
            query: (id) => ({
                url: `${authBase}/safe-zones/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['saveZones'],
        }),
    }),
    overrideExisting: false,
})

// export const familyApi = createApi({
//   reducerPath: 'familyApi',
//   baseQuery,
//   tagTypes: ['family', 'saveZones'],
//   endpoints: (builder) => ({
//     getFamily: builder.query<IResponseType<IFamilyMember[]>, void>({
//       query: () => `${authBase}`,
//     }),
//     inviteFamily: builder.mutation<FamilyMemberUpdateResponse, IFamilyMember>({
//       query: (body) => ({
//         url: `${authBase}/safe-zones`,
//         method: 'POST',
//         body,
//       }),
//     }),
//     getSaveZones: builder.query<IResponseType<ISaveZone[]>, string>({
//       query: (qwery = '') => `${authBase}/safe-zones?${qwery}`,
//       providesTags: ['saveZones'],
//     }),
//     createSaveZone: builder.mutation<
//       IResponseType<ISaveZone>,
//       ISaveZone
//     >({
//       query: (body) => ({
//         url: `${authBase}/safe-zones`,
//         method: 'POST',
//         body,
//       }),
//       invalidatesTags: ['saveZones'],
//     }),
//     updateSaveZone: builder.mutation<
//       IResponseType<ISaveZone>,
//       { id: number; body: ISaveZone }
//     >({
//       query: ({ id, body }) => ({
//         url: `${authBase}/safe-zones/${id}`,
//         method: 'PUT',
//         body,
//       }),
//     }),
//     manageInviteStatus: builder.mutation<
//       IResponseType<IFamilyMember>,
//       {
//         id: number
//         body: { status: FAMILY_STATUS.Accepted | FAMILY_STATUS.Rejected }
//       }
//     >({
//       query: ({ id, body }) => ({
//         url: `${authBase}/invite/${id}`,
//         method: 'PUT',
//         body,
//       }),
//     }),
//     updateFamilyMember: builder.mutation<
//       IResponseType<IFamilyMember>,
//       { id: number; body: IFamilyMember }
//     >({
//       query: ({ id, body }) => ({
//         url: `${authBase}/members/${id}`,
//         method: 'PUT',
//         body,
//       }),
//     }),
//     deleteFamilyMember: builder.mutation<ResponseWidthOutDataType, number>({
//       query: (id) => ({
//         url: `${authBase}/members/${id}`,
//         method: 'DELETE',
//       }),
//     }),
//     deleteSaveZone: builder.mutation<ResponseWidthOutDataType, number>({
//       query: (id) => ({
//         url: `${authBase}/safe-zones/${id}`,
//         method: 'DELETE',
//       }),
//       invalidatesTags: ['saveZones'],
//     }),
//   }),
// })

export const {
    useCreateSaveZoneMutation,
    useDeleteFamilyMemberMutation,
    useDeleteSaveZoneMutation,
    useGetFamilyQuery,
    useGetSaveZonesQuery,
    useInviteFamilyMutation,
    useUpdateFamilyMemberMutation,
    useUpdateSaveZoneMutation,
    useManageInviteStatusMutation,
    useGetOffendersMapQuery,
    useLazyGetOffendersMapQuery,
    useLazyGetLocationHistoryQuery,
    useLazyGetMemberTripsQuery,
    useGetAllFamiliesQuery,
    useCreateFamilyMutation,
    useDeleteFamilyMutation,
    useGetFamilyByIdQuery,
} = familyApi
