import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import NextIcon from '../../assets/img/ico-pagination--next.svg?react'
import PrevIcon from '../../assets/img/ico-pagination--prev.svg?react'
import MarkAsReadIcon from '../../assets/img/icon-mark-as-read.svg?react'
import Button from '../../compoments/Button'
import { PageLoader } from '../../compoments/PageLoader'
import { useAppSelector } from '../../hooks/redux'
import { useGetMeQuery } from '../../services/auth'
import {
    useLazyCountNotQuery,
    useLazyGetAllNotificationsQuery,
    useLazyReadAllNotQuery,
} from '../../services/notifications'
import type { INotification } from '../../services/types'

import Notification from './components/Notification'
import Pagination from '../../compoments/Pagination'

interface FormattedNotification extends INotification {
    title: string
    content: string
}

const NotificationsPage = () => {
    const { t } = useTranslation('notifications')
    const { data: userData } = useGetMeQuery()
    const [searchParams, setSearchParams] = useSearchParams()
    const [triggerNotifications, { data: notificationsData, isLoading }] =
        useLazyGetAllNotificationsQuery()
    const [triggerReadAll] = useLazyReadAllNotQuery()
    const { notificationCount } = useAppSelector((state) => state.allerts)
    const [notRead, setNotRead] = useState(0)

    useEffect(() => {
        if (userData?.data?.id) {
            triggerNotifications(searchParams.toString())
        }
    }, [userData?.data, searchParams])

    const [notifications, setNotifications] = useState<FormattedNotification[]>(
        [],
    )

    useEffect(() => {
        if (notificationsData?.data) {
            setNotifications(
                notificationsData?.data.map((n) => ({
                    ...n,
                    title:
                        n.type === 'zip'
                            ? `Notification zip ${n.offenderDocumentId}`
                            : 'Notification ',
                    content:
                        'Here will be a description of the notification...',
                })),
            )
            setNotRead(
                notificationsData?.data.filter(
                    (one) => !one?.notificationReadStatus,
                ).length,
            )
        }
    }, [notificationsData])

    const handleReadAll = async () => {
        const res = await triggerReadAll().unwrap()

        if (res.success) {
            toast.success(res.message)
        }
    }
    const handlePageChange = (page: number) => {
        setSearchParams((prev) => {
            prev.set('page', String(page))
            return prev
        })
    }
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <div className="container h-full">
                <div className="card card-border h-full notifications-page">
                    <div className="card-header border-none">
                        <div className="card-header--item gap-12">
                            <h1 className="heading font-500">{t('title')}</h1>
                            {!!notificationCount && (
                                <span className="notifications-cunter">
                                    {notificationCount}
                                </span>
                            )}
                        </div>
                        <div className="card-header--item">
                            <Button
                                onClick={handleReadAll}
                                className="btn btn--primary btn--lg rounded-full"
                            >
                                <span className="ico">
                                    <MarkAsReadIcon />
                                </span>
                                {t('read')}
                            </Button>
                        </div>
                    </div>
                    <div className="card-body overflow-hidden">
                        <div className="table-wrapper scroll-styler">
                            <table
                                className="table table--notifications"
                                cellPadding="0"
                                cellSpacing="0"
                            >
                                <tbody>
                                    {notifications?.length
                                        ? notifications.map(
                                            (notification, index) => (
                                                <tr
                                                    key={index}
                                                    className={clsx({
                                                        'new-notification':
                                                            !notification?.notificationReadStatus ||
                                                            false,
                                                    })}
                                                >
                                                    <td>
                                                        <Notification
                                                            {...notification}
                                                        />
                                                    </td>
                                                </tr>
                                            ),
                                        )
                                        : null}
                                </tbody>
                            </table>
                        </div>
                        {/* <ReactPaginate
                            breakLabel="..."
                            previousLabel={<PrevIcon />}
                            nextLabel={<NextIcon />}
                            previousClassName="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
                            nextClassName="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
                            pageCount={
                                notificationsData?.pagination?.maxPages || 0
                            }
                            containerClassName="pagination"
                            pageClassName="pagination-item"
                            pageLinkClassName="btn btn--square btn--sm rounded-full btn-pagination"
                            activeLinkClassName="--active"
                            pageRangeDisplayed={5}
                            onPageChange={(selectedItem) =>
                                handlePageChange(selectedItem.selected + 1)
                            }
                            renderOnZeroPageCount={null}
                        /> */}
                        <Pagination
                            pageCount={notificationsData?.pagination?.maxPages || 0}
                            onPageChange={(
                                page: number,
                            ) => {
                                handlePageChange(page)
                            }}
                        />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NotificationsPage
