import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { IArestInformations } from '../types'

import { baseApi } from './base'
import type { CrimesData, OffendersData } from './types'

const usersBase = 'api/radar-service/search'

export interface IBodyUsers {
    name?: string
    zip?: string
}
export interface IBodyCrimes {
    zip?: string
}

export const usersApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<OffendersData, IBodyUsers>({
            query: (body) => ({
                // if (body?.name) {
                //   return `${usersBase}?name=${body.name}&miles=10`
                // } else if (body?.zip) {
                //   return `${usersBase}?zipcode=${body.zip}&miles=10`
                // } else {
                //   return `${usersBase}`
                // }
                url: `${usersBase}?isSearch=true`,
                params: {
                    name: body.name,
                    zipcode: body.zip,
                    miles: body.zip ? undefined : 10,
                },
            }),
        }),
        getCrimes: builder.query<CrimesData, IBodyCrimes>({
            query: (body) => ({
                // if (body?.name) {
                //   return `${usersBase}?name=${body.name}&miles=10`
                // } else if (body?.zip) {
                //   return `${usersBase}?zipcode=${body.zip}&miles=10`
                // } else {
                //   return `${usersBase}`
                // }
                url: `${usersBase}/crimes`,
                params: {
                    from: 0,
                    zipcode: body.zip,
                    miles: 15,
                },
            }),
        }),
        getArestInformations: builder.query<IArestInformations, number>({
            query: (id) => `${usersBase}/charges?offenderId=${id}`,
        }),
        getProfilesUsers: builder.mutation<any, any>({
            query: (body) => ({
                url: `api/radar-service/user/offender`,
                method: 'POST',
                body,
            }),
        }),
        //
        // getMe: builder.query<IUser, void>({
        //     query: () => `api/users/me`,
        // }),
    }),
})

export const {
    useGetProfilesUsersMutation,
    useGetUsersQuery,
    useLazyGetUsersQuery,
    useLazyGetCrimesQuery,
    useLazyGetArestInformationsQuery,
} = usersApi
