import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "./redux";



interface ProtectedRouteProps {
  redirectPath?: string;
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirectPath = '/login-email',
  children,
}) => {
  const me = useAppSelector(state => state.auth.user)
  if (!me) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;