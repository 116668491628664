import { toast } from 'react-toastify'

import NotificationsIcon from '../../../assets/img/ico-notification-2.svg?react'
import Button from '../../../compoments/Button'
import { FAMILY_STATUS } from '../../../constants/statuses'
import { useManageInviteStatusMutation } from '../../../services/family'
import { INotification } from '../../../services/types'

interface NotificationProps extends INotification {
    title: string
    content: string
    payload?: {
        timestamp?: Date
        userId?: number
        userName?: string
        invitationId?: number
        isProcessed?: boolean
    }
}

const Notification = ({ title, content, type, ...rest }: NotificationProps) => {
    const [manageInviteStatus, { data, isLoading, isSuccess }] =
        useManageInviteStatusMutation()
    console.log(rest)
    const handleChangeInviteStatus = (
        status: FAMILY_STATUS.Accepted | FAMILY_STATUS.Rejected,
    ) => {
        const invitationId = rest.payload?.invitationId
        if (!invitationId) return
        manageInviteStatus({
            id: invitationId,
            body: {
                status: status,
            },
        })
        if (isSuccess && data.success) {
            toast.success(data.message)
        } else if (isSuccess && !data.success) {
            toast.success(data.message)
        }
    }
    return (
        <div className="flex items-center gap--md flex-wrap">
            <div className="flex items-center gap--md flex-auto">
                <div className="fake-btn btn btn--outline-danger rounded-full btn--square btn--md !overflow-visible">
                    <span className="ico">
                        <NotificationsIcon />
                    </span>
                </div>

                <div className="notification-block flex-auto">
                    <p className="notification-title text--lg font-600">{title}</p>
                    <p className="text--lg ">{content}</p>
                </div>
            </div>
            {type === 'invitation' && !rest.payload?.isProcessed && (
                <div className="row-group gap--sm">
                    <Button
                        isLoading={isLoading}
                        onClick={() =>
                            handleChangeInviteStatus(FAMILY_STATUS.Rejected)
                        }
                        className="btn btn--outline-danger btn--lg rounded-full"
                    >
                        Decline
                    </Button>
                    <Button
                        isLoading={isLoading}
                        onClick={() =>
                            handleChangeInviteStatus(FAMILY_STATUS.Accepted)
                        }
                        className="btn btn--primary btn--lg rounded-full"
                    >
                        Accept
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Notification
