import { formatDistanceToNow } from 'date-fns'
import {
    enUS,
    zhCN,
    de,
    es,
    fr,
    hi,
    id,
    it,
    ja,
    ko,
    nl,
    pt,
    arSA,
    tr,
    uk,
} from 'date-fns/locale'

import default_placeholder from '../assets/img/ico-age-range.svg'
export const returnOnlyNumbers = (value: string) => {
    return value.replace(/\D/g, '')
}

export const getDomain = () => {
    const host = window.location.hostname
    const isDev = ['localhost', '127.0.0.1'].includes(host)
    // return import.meta.env.VITE_API_URL
    return isDev ? import.meta.env.VITE_API_URL : window.location.origin
}
export function isZipCode(input: string) {
    // Регулярное выражение для проверки ZIP-кода (5 или 9 цифр)
    const zipCodeRegex = /^\d{5}(-\d{4})?$/;
    return zipCodeRegex.test(input);
}
export const getMediaUrl = (
    path: string | null | undefined,
    placeholder: string = default_placeholder,
) => {
    const base = getDomain()
    if (path) {
        return base + path
    }
    return placeholder
}
export const getExternalMediaUrl = (
    path: string | null | undefined,
    placeholder: string = default_placeholder,
) => {
    const base = getDomain() + '/api/radar-service/files/'
    if (path) {
        return base + path
    }
    return placeholder
}
export const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
    placeholder: string = default_placeholder,
): void => {
    e.currentTarget.src = placeholder
}

export function isValidDate(date: any): boolean {
    const parsedDate = new Date(date)
    return !isNaN(parsedDate.getTime())
}

export const locales = {
    en: enUS,
    cn: zhCN,
    de: de,
    es: es,
    fr: fr,
    in: hi,
    it: it,
    jp: ja,
    kr: ko,
    nl: nl,
    pt: pt,
    sa: arSA,
    tr: tr,
    ua: uk,
}

export function timeAgo(
    dateInput: Date | string | number,
    localeCode: keyof typeof locales = 'en',
): string {
    try {
        const date = new Date(dateInput)

        if (isNaN(date.getTime())) {
            throw new Error('Invalid date')
        }
        const locale = locales[localeCode]
        // Форматируем разницу времени
        const fullFormat = formatDistanceToNow(date, {
            addSuffix: true,
            locale,
        })

        // Заменяем длинные формы на сокращения
        if (localeCode === 'en') {
            return fullFormat
                .replace(/minutes?/g, 'mins')
                .replace(/seconds?/g, 'secs')
                .replace(/hours?/g, 'hrs')
                .replace(/days?/g, 'days')
                .replace(/months?/g, 'mos')
                .replace(/years?/g, 'yrs')
        }

        return fullFormat
    } catch (error) {
        console.error(error)
        return 'Invalid date'
    }
}

export const joinStrings = (
    strings: string[],
    separator: string = '',
): string => {
    return strings.filter(Boolean).join(separator)
}
