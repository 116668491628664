import { format, differenceInMinutes } from 'date-fns'
import React, { useEffect, useState } from 'react'
import Map, { Source, Layer, Marker } from 'react-map-gl'

import DistanceIcon from '../../../assets/img/distance.svg?react'
import type { Trip, TripCoordinate } from '../../../services/types'

const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY

interface TripItemProps {
    trip: Trip
}

const fetchAddress = async (data: TripCoordinate) => {
    try {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${data.longitude},${data.latitude}.json?access_token=${mapBoxToken}`,
        )
        const result = await response.json()
        if (result.features && result.features.length > 0) {
            const fullAddress = result.features[0].place_name
            const streetAddress = fullAddress.split(',')[0].trim()
            return streetAddress
        }
    } catch (error) {
        return 'Address not found'
    }
}

const TripItem: React.FC<TripItemProps> = ({ trip }) => {
    console.log('trip', trip)
    const [startAddress, setStartAddress] = useState<string>('Loading...')
    const [finishAddress, setFinishAddress] = useState<string>('Loading...')

    useEffect(() => {
        const loadAddresses = async () => {
            if (trip.coordinates.length > 0) {
                const start = trip.coordinates[0]
                const finish = trip.coordinates[trip.coordinates.length - 1]
                setStartAddress(await fetchAddress(start))
                setFinishAddress(await fetchAddress(finish))
            }
        }
        loadAddresses()
    }, [trip.coordinates])

    const totalMinutes = differenceInMinutes(
        new Date(trip.coordinates[trip.coordinates.length - 1].timestamp),
        new Date(trip.coordinates[0].timestamp),
    )
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    const duration = `${hours > 0 ? `${hours} hr, ` : ''}${minutes} min`

    const pointsJson = trip.coordinates.map((coord) => {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [coord.longitude, coord.latitude],
            },
        }
    })

    const geojson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: trip.coordinates.map((coord) => [
                        coord.longitude,
                        coord.latitude,
                    ]),
                },
            },
          ...pointsJson
        ],
    }

    if (!trip.coordinates.length) return null

    return (
        <div className="trip-item items-start">
            <div className="flex gap--lg ">
                <div className="trip-item__distance">
                    <DistanceIcon />
                </div>
                <div className="flex flex-col justify-evenly">
                    <div className="text--lg font-600">
                        {' '}
                        {(+trip.distance / 1000).toFixed(2)} km
                    </div>
                    <div className="text--sm font-400 color-gray">
                        {`${format(
                            new Date(trip.coordinates[0].timestamp),
                            'hh:mm a',
                        )} - ${format(
                            new Date(
                                trip.coordinates[
                                    trip.coordinates.length - 1
                                ].timestamp,
                            ),
                            'hh:mm a',
                        )} (${duration})`}
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="trip-item-map">
                    <Map
                        initialViewState={{
                            latitude: trip.coordinates[0].latitude,
                            longitude: trip.coordinates[0].longitude,
                            zoom: 18,
                        }}
                        accessToken={mapBoxToken}
                        style={{ width: '100%', height: '100%' }}
                        mapStyle="mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0"
                    >

                        <Source
                            id={`trip`}
                            type="geojson"
                            data={geojson}
                        >
                            <Layer
                                id={`line`}
                                type="line"
                                paint={{
                                    'line-color': '#F41658',
                                    'line-width': 1,
                                }}
                            />
                            <Layer
                                id={`points`}
                                type="circle"
                                paint={{
                                    'circle-color': '#253237',
                                    "circle-radius": 5,
                                    "circle-stroke-width": 2,
                                    "circle-stroke-color": "#ffffff",
                                }}
                            />
                        </Source>
                    </Map>
                </div>
                <div className="trip-item__info">
                    <div className="flex flex-col text-center">
                        <div className="text--lg font-600 mb-2">
                            {startAddress}
                        </div>
                        <div className="text--sm font-400 color-gray">
                            Start
                        </div>
                    </div>
                    <div className="flex flex-col text-center">
                        <div className="text--lg font-600 mb-2">
                            {finishAddress}
                        </div>
                        <div className="text--sm font-400 color-gray">
                            Finish
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TripItem
