import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import type { InferType } from 'yup'

import Input from '../../../compoments/Input'
import Modal from '../../../compoments/Modal'
import { useCreateFamilyMutation } from '../../../services/family'
import { familySchema } from '../../../shemas/validationSchema'

interface AddFamilyMemberProps {
    isOpen: boolean
    onClose: () => void
}
const AddFamilyMember = ({ isOpen, onClose }: AddFamilyMemberProps) => {
       const { t } = useTranslation('members')
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(familySchema),
    })
    const [createFamily] = useCreateFamilyMutation()

    const onSubmit = async (dataForm: InferType<typeof familySchema>) => {
        const formData = new FormData()

        Object.entries(dataForm).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value as string)
            }
        })

        try {
            const response = await createFamily(formData).unwrap()
            if (response.success) {
                onClose()
            } else {
                toast.error(response?.message)
            }
        } catch (error) {
            console.error('Error creating family:', error)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            modalSize={'modal__dialog--410'}
        >
            <div className="modal__header">
                <div className="modal__header-block">
                    <h2 className="modal__header-title">{t('families.create')}</h2>
                    <p className="modal__header-description">
                        {t('families.create_title')}
                    </p>
                </div>
            </div>
            <div className="modal__body">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <Input
                            label={t('families.name')}
                            placeholder="Enter family name..."
                            inputType="input--outline"
                            {...register('name')}
                            error={errors?.name?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                <button
                    type="button"
                    onClick={onClose}
                    className="btn btn--secondary-light btn--lg rounded-full w-full"
                >
                    {t('families.cancel')}
                </button>
                <button
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn--primary btn--lg rounded-full w-full"
                >
                    {t('families.submit')}
                </button>
            </div>
        </Modal>
    )
}

export default AddFamilyMember
