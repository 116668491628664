import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Logo from '../assets/img/logo-60x60.svg'
import Option1 from '../assets/img/premium-option-1.svg'
import Option2 from '../assets/img/premium-option-2.svg'
import Option3 from '../assets/img/premium-option-3.svg'
import Option4 from '../assets/img/premium-option-4.svg'
import Option5 from '../assets/img/premium-option-5.svg'
import Option6 from '../assets/img/premium-option-6.svg'
import Option7 from '../assets/img/premium-option-7.svg'
import Option8 from '../assets/img/premium-option-8.svg'
import { getPremiumStatus, getUser } from '../features/auth/authSlice'
import { useAppSelector } from '../hooks/redux'
import {
    useCreateSubscriptionMutation,
    useLazyGetMySubscriptionQuery,
} from '../services/subscriptions'
import type { SubscriptionPeriod } from '../types'

import Button from './Button'
import Modal from './Modal'

interface UpdatePremiumModalProps {
    isOpen: boolean
    onClose: () => void
}

const plans = [
    {
        id: 1,
        text: 'week',
        price: '5.99',
        period: 'weekly',
        pay_period: 'week',
        popular: false,
    },
    {
        id: 2,
        text: 'month',
        price: '12.99',
        period: 'monthly',
        pay_period: 'month',
        popular: false,
    },
    {
        id: 3,
        text: 'year',
        price: '7.99',
        period: 'yearly',
        pay_period: 'month',
        popular: true,
    },
]

const UpdatePremiumModal = ({ onClose, isOpen }: UpdatePremiumModalProps) => {
    const { t } = useTranslation('premium')
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const user = useAppSelector(getUser)
    const premiumStatus = useAppSelector(getPremiumStatus)
    const [createSubscription, { isLoading }] = useCreateSubscriptionMutation()
    const [getMySubscription, { data: subscriptionData, isFetching }] =
        useLazyGetMySubscriptionQuery()
    const [active, setActive] = useState(1)

    const options = [
        {
            text: t('options.option_1'),
            img: Option1,
        },
        {
            text: t('options.option_2'),
            img: Option2,
        },
        {
            text: t('options.option_3'),
            img: Option3,
        },
        {
            text: t('options.option_4'),
            img: Option4,
        },
        {
            text: t('options.option_5'),
            img: Option5,
        },
        {
            text: t('options.option_6'),
            img: Option6,
        },
        {
            text: t('options.option_7'),
            img: Option7,
        },
        {
            text: t('options.option_8'),
            img: Option8,
        },
    ]

    useEffect(() => {
        if (isOpen) {
            getMySubscription()
        }
    }, [isOpen, getMySubscription])

    const [subscriptionPeriod, setSubscriptionPeriod] =
        useState<SubscriptionPeriod>('weekly')


    
    const handleSubmit = async () => {
        if(user?.demo) {
            navigate('/create-account')
            onClose()
            return
        }
        if (!user) {
            navigate('/login-email')
            onClose()
            return
        }
        const response = await createSubscription({
            type: subscriptionPeriod,
        }).unwrap()
        const clientSecret = response?.data.clientSecret
        if (clientSecret) {
            onClose()
            navigate(`/checkout/${clientSecret}`, {
                state: {
                    redirectTo: pathname,
                },
            })
        }
    }
    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            modalClassNames={'upgrade-premium premium-bg'}
            modalSize={'modal__dialog--410 '}
        >
            <div className="modal__header">
                <div className="upgrade-premium__logo mb-22">
                    <img src={Logo} alt="logo" />
                </div>
                <h1 className="upgrade-premium__title">
                    Get Guarderly <span className="gradient-text">Premium</span>
                </h1>
                <p className="upgrade-premium__subtitle">{t('description')}</p>
            </div>
            <div className="modal__body">
                <div className="premium__plans">
                    {plans.map((plan) => (
                        <div
                            key={plan.id}
                            className={`premium__plan-item 
                            ${active === plan.id ? 'active' : ''} 
                            ${plan.popular ? 'popular' : ''}`}
                            onClick={() => {
                                setActive(plan.id)
                                setSubscriptionPeriod(
                                    plan.period as SubscriptionPeriod,
                                )
                            }}
                        >
                            <div className="premium__plan-item-group">
                                <span className="premium-plans-item--text">
                                    1
                                </span>
                                <span>{plan.text}</span>
                            </div>
                            <div className="premium__plan-item-group">
                                <span className="premium-plans-item--text">
                                    ${plan.price}
                                </span>
                                <span>/{plan.pay_period}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <ul className="premium__options-list">
                    {options.map((option, index) => (
                        <li className="premium__option-item" key={index}>
                            <div className="premium__option-icon">
                                <img src={option.img} alt="option" />
                            </div>
                            <p className="premium__option-text">
                                {option.text}
                            </p>
                        </li>
                    ))}
                </ul>

                <div className="modal__footer col-group">
                    <Button
                        isLoading={isLoading}
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn--lg  btn--gradient rounded-full"
                    >
                        <span className="ico">
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.8083 12.4319L20.0191 10.1989C20.1841 8.44621 20.2666 7.56896 19.9659 7.20687C19.8901 7.11277 19.7963 7.03461 19.6901 6.97687C19.584 6.91913 19.4674 6.88297 19.3472 6.87046C18.9108 6.83196 18.3636 7.45529 17.2682 8.70196C16.7017 9.34729 16.4184 9.66904 16.1031 9.71946C15.927 9.7471 15.7466 9.71822 15.5879 9.63696C15.2955 9.49029 15.1021 9.09154 14.7125 8.29496L12.6619 4.09296C11.9268 2.58687 11.5592 1.83337 11 1.83337C10.4408 1.83337 10.0733 2.58687 9.33809 4.09296L7.2875 8.29496C6.89884 9.09246 6.7045 9.49029 6.41209 9.63696C6.25321 9.71762 6.07304 9.74647 5.89692 9.71946C5.58159 9.66904 5.29834 9.34729 4.73184 8.70196C3.63642 7.45529 3.08917 6.83196 2.65284 6.87046C2.53261 6.88297 2.41605 6.91913 2.30986 6.97687C2.20367 7.03461 2.10995 7.11277 2.03409 7.20687C1.73434 7.56896 1.81684 8.44621 1.98184 10.1989L2.19175 12.4319C2.53825 16.1095 2.71059 17.9493 3.79592 19.0575C4.8785 20.1667 6.50284 20.1667 9.75334 20.1667H12.2458C15.4953 20.1667 17.1197 20.1667 18.2041 19.0575C19.2885 17.9484 19.4618 16.1095 19.8083 12.4319Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <span>Upgrade</span>
                    </Button>
                    <p className="color-text text-center">{t('footer_desc')}</p>
                </div>
            </div>
        </Modal>
    )
}

export default UpdatePremiumModal
