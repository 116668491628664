import type { IUser } from '../types'

import { baseApi } from './base'
import type {
    ChangePasswordRequestType,
    IResponseType,
    LoginPhoneRequestType,
    LoginPhoneResponseType,
    RecoveryResponseType,
    RegisterResponseType,
    TestRecoveryRequestType,
} from './types'

const authBase = 'api/auth'

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<any, any>({
            query: (body) => ({
                url: `${authBase}/signin`,
                method: 'POST',
                body,
            }),
        }),
        loginDemo: builder.mutation<any, void>({
            query: () => ({
                url: `${authBase}/demo`,
                method: 'POST',
            }),
        }),

        loginGoogle: builder.query<any, void>({
            query: () => `/oauth/google`,
        }),

        // TODO типизировать запрос ответ
        register: builder.mutation<any, any>({
            query: (body) => ({
                url: `${authBase}/signup`,
                method: 'POST',
                body,
            }),
        }),

        getMe: builder.query<IResponseType<IUser>, void>({
            query: () => `api/users/me`,
            providesTags: ['me'],
        }),

        updateMe: builder.mutation<any, any>({
            query: (body) => ({
                url: `api/users/me`,
                method: 'PUT',
                body,
            }),
        }),

        forgotPassword: builder.mutation<any, any>({
            query: (body) => ({
                url: `${authBase}/forgot-password`,
                method: 'POST',
                body,
            }),
        }),

        loginByPhone: builder.mutation<any, any>({
            query: (body) => ({
                url: `${authBase}/signin-by-phone`,
                method: 'POST',
                body,
            }),
        }),
        requestRecovery: builder.mutation<RecoveryResponseType, string>({
            query: (email) => ({
                url: `${authBase}/forgot-password`,
                method: 'POST',
                body: {
                    email,
                },
            }),
        }),
        testRecoveryCode: builder.mutation<
            RecoveryResponseType,
            TestRecoveryRequestType
        >({
            query: (body) => ({
                url: `${authBase}/test-forgot-code`,
                method: 'POST',
                body,
            }),
        }),
        changePassword: builder.mutation<
            RegisterResponseType,
            ChangePasswordRequestType
        >({
            query: (body) => ({
                url: `${authBase}/restore-password`,
                method: 'POST',
                body,
            }),
        }),
        loginPhone: builder.mutation<
            LoginPhoneResponseType,
            LoginPhoneRequestType
        >({
            query: (body) => ({
                url: `${authBase}/signin-by-phone`,
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const {
    useLoginByPhoneMutation,
    useLazyLoginGoogleQuery,
    useForgotPasswordMutation,
    useLoginMutation,
    useGetMeQuery,
    useRegisterMutation,
    useUpdateMeMutation,
    useRequestRecoveryMutation,
    useTestRecoveryCodeMutation,
    useChangePasswordMutation,
    useLoginPhoneMutation,
    useLazyGetMeQuery,
    useLoginDemoMutation
} = authApi
