/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'

import { useLazyGetMemberTripsQuery } from '../../../services/family'
import type { Trip, TripsResponse } from '../../../services/types'
import type { IFamily, IFamilyMember, ISaveZone } from '../../../types'

import Map from './Map'

import { endOfDay, format, startOfDay } from 'date-fns'

import TripItem from './TripItem'

interface IFamilyOffenders {
    saveZones: ISaveZone[]
    users: IFamilyMember[]
    family: IFamily
    selectedUser: IFamilyMember | null
    setSelectedUser: (value: IFamilyMember | null) => void
}

interface CheckboxOption {
    id: 'offenders' | 'members'
    label: string
}

export type VisibleFamilyMapItems = {
    [key in CheckboxOption['id']]: boolean
}

const FamilyOffenders = ({
    saveZones,
    users,
    family,
    selectedUser,
    setSelectedUser,
}: IFamilyOffenders) => {
    const { t } = useTranslation('members')
    const navigate = useNavigate()
    const options: CheckboxOption[] = [
        { id: 'offenders', label: t('filters.sex_offenders') },
        { id: 'members', label: t('filters.family_members') },
    ]
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [getMemberTrips, { data: tripsData, isLoading: tripsLoading }] =
        useLazyGetMemberTripsQuery()

    useEffect(() => {
        if (selectedUser && selectedDate) {
            const startDate = startOfDay(selectedDate).toISOString()
            const endDate = endOfDay(selectedDate).toISOString()
            getMemberTrips({
                id: selectedUser?.member?.id || 1,
                startDate,
                endDate,
            })
        }
    }, [selectedUser, selectedDate, getMemberTrips])

    const [visibleItems, setVisibleItems] = useState<VisibleFamilyMapItems>({
        offenders: true,
        members: true,
    })
    const handleCheckboxChange = (id: CheckboxOption['id']) => {
        setVisibleItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }))
    }
    return (
        <div className="catalog-page-content">
            <div className="card card-border h-full scroll-card">
                <div className="card-header header-similar card-header-catalog">
                    <div className="card-header--item card-header--item--overflow">
                        <button
                            type="button"
                            onClick={() =>
                                selectedUser
                                    ? setSelectedUser(null)
                                    : navigate('/family')
                            }
                            className="btn"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                            >
                                <path
                                    d="M21 12.4277H4.5"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M11.25 5.67773L4.5 12.4277L11.25 19.1777"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>

                        <h2 className="heading font-500 card-header--item--title">
                            {selectedUser
                                ? selectedUser.fullName
                                : family?.name}
                        </h2>
                    </div>
                    {!selectedUser && (
                        <div className="card-header--item">
                            {options.map(({ id, label }) => (
                                <label
                                    key={id}
                                    className="check-form check--xs check-rounded-full"
                                >
                                    <input
                                        type="checkbox"
                                        id={id}
                                        hidden
                                        checked={visibleItems[id]}
                                        onChange={() =>
                                            handleCheckboxChange(id)
                                        }
                                    />
                                    <div className="check-row">
                                        <span className="check-control check-control--custom"></span>
                                        <div className="check-content my-auto">
                                            <p className="check-text text--md">
                                                {label}
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            ))}
                        </div>
                    )}
                    {selectedUser && (
                        <div className="card-header--item ">
                            <div style={{ width: 150, height: 24 }}>
                                <DatePicker
                                    selected={selectedDate || new Date()}
                                    className="calendar-title"
                                    onChange={(date: Date | null) =>
                                        setSelectedDate(date as Date)
                                    }
                                    popperPlacement="bottom"
                                    dateFormat="EEE, dd MMM yyyy"
                                    customInput={
                                        <div>
                                            {selectedDate
                                                ? format(
                                                      selectedDate,
                                                      'EEE, dd MMM yyyy',
                                                  )
                                                : ''}
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
                {!selectedUser && (
                    <div className="card-body p-0 scroll-card h-full">
                        <div
                            className={`tab h-full active-tab`}
                            data-id="map-view"
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Map
                                    users={users}
                                    selectedUser={selectedUser}
                                    setSelectedUser={setSelectedUser}
                                    saveZones={saveZones}
                                    visibleItems={visibleItems}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {selectedUser && tripsData && (
                    <div className="card-body p-0 scroll-card h-full">
                        {tripsData.data.length > 0 ? (
                            tripsData.data.map((trip: Trip) => (
                                <TripItem trip={trip} />
                            ))
                        ) : (
                            <h2 className="text-center m-5">No trips</h2>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default FamilyOffenders
